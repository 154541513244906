import {
  CONSULT_CEP_CLEAR,
  CONSULT_CEP_FAILURE,
  CONSULT_CEP_REQUEST,
  CONSULT_CEP_SUCCESS,
  UPDATE_CEP,
} from "../actions";

export const INIT_STATE = {
  cep: "",
  response: {},
  busca_loading: false,
  update_: false,
  error_busca: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONSULT_CEP_REQUEST:
      return {
        ...state,
        busca_loading: true,
        cep: action.payload.cep,
        error_busca: false,
      };
    case CONSULT_CEP_SUCCESS:
      return {
        ...state,
        busca_loading: false,
        response: action.payload.response,
        error_busca: false,
      };
    case CONSULT_CEP_FAILURE:
      return { ...state, busca_loading: false, error_busca: true };
    case CONSULT_CEP_CLEAR:
      return { response: {}, cep: "", busca_loading: false };
    case UPDATE_CEP:
      return { ...state, update_: action.payload.update_ };
    default:
      return { ...state };
  }
};

export default reducer;
