import { setCurrentLanguage } from "../../../helpers/Utils";
import {
  CHANGE_LOCALE,
  USER_CHANGE_IMAGE,
  USER_UPDATE,
  USER_UPDATE_RESULT,
  USER_UPDATE_FAILURE,
} from "../actions";

export const changeLocale = (locale) => {
  setCurrentLanguage(locale);
  return {
    type: CHANGE_LOCALE,
    payload: locale,
  };
};
export const userChangeImage = (url) => ({
  type: USER_CHANGE_IMAGE,
  payload: { url },
});
export const userUpdate = (user) => ({
  type: USER_UPDATE,
  payload: { user },
});
export const userUpdateResult = (resultUpdate) => ({
  type: USER_UPDATE_RESULT,
  payload: { resultUpdate },
});
export const userUpdateFailure = (payload) => ({
  type: USER_UPDATE_FAILURE,
  payload: payload,
});
