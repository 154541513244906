import { takeEvery, all, fork, put, call } from "redux-saga/effects";
import { baseUrl } from "../../../services";
import {
  CONTEMPLACAO_REQUEST,
  CREATE_CONTEMPLACAO_REQUEST,
  PROPOSTAS_OPTIONS_REQUEST,
  ASSEMBLY_OPTIONS_REQUEST,
  EDIT_CONTEMPLACAO_REQUEST,
  GET_DATA_REQUEST,
  FORM_CONTEMPLACAO_REQUEST,
  DELETE_CONTEMPLACAO_PROPOSTA_REQUEST,
} from "../actions";
import {
  formContemplacaoSuccess,
  formContemplacaoFailure,
  contemplacaoSuccess,
  createContemplacaoSuccess,
  loadPropostaOptionsSuccess,
  loadAssemblyOptionsSuccess,
  getDataSuccess,
  editContemplacaoSuccess,
  createContemplacaoFailure,
  deleteContemplacaoPropostaFailure,
  deleteContemplacaoPropostaSuccess,
} from "./actions";
import {
  Contemplacao,
  CreateContemplacao,
  LoadPropostasOptions,
  LoadAssemblyOptions,
  EditContemplacao,
  GetData,
  DeleteContemplacaoProposta,
} from "../../../constants/endPointValues";

function* watchContemplacao() {
  yield takeEvery(CONTEMPLACAO_REQUEST, listContemplacao);
}

function* watchformContemplacaoRequest() {
  yield takeEvery(FORM_CONTEMPLACAO_REQUEST, formContemplacaoSubmitForm);
}

function* watchloadPropostasContemplacao() {
  yield takeEvery(PROPOSTAS_OPTIONS_REQUEST, loadPropostasOptions);
}

function* watchAssemblyContemplacao() {
  yield takeEvery(ASSEMBLY_OPTIONS_REQUEST, loadAssemblyOptions);
}

function* watchGetData() {
  yield takeEvery(GET_DATA_REQUEST, getData);
}

function* watchDeleteContemplacaoProposta() {
  yield takeEvery(
    DELETE_CONTEMPLACAO_PROPOSTA_REQUEST,
    deleteContemplacaoProposta
  );
}

function* deleteContemplacaoProposta({ payload }) {
  try {
    const { id } = payload;
    yield call(baseUrl.post, `${DeleteContemplacaoProposta}?id=${id}`);

    yield put(
      deleteContemplacaoPropostaSuccess({
        success: true,
        status_code: 200,
        http_messages: ["Contemplação excluída com sucesso!"],
      })
    );
  } catch (err) {
    yield put(
      deleteContemplacaoPropostaFailure({
        error: true,
        status_code: 500,
        http_messages: [
          "Não foi possível excluir a contemplação. Tente novamente mais tarde",
        ],
      })
    );
  }
}

function* listContemplacao({ payload }) {
  const { filter } = payload;

  const {
    data: { Items, iTotalRecords, iTotalDisplayRecords },
  } = yield call(baseUrl.post, Contemplacao, filter);

  yield put(
    contemplacaoSuccess({ Items, iTotalRecords, iTotalDisplayRecords })
  );
}

function* formContemplacaoSubmitForm({ payload }) {
  try {
    const { values } = payload;
    const { id } = payload;
    const { EnabledNotification, PropostaID } = values;

    const endPoint =
      id === "0"
        ? `${CreateContemplacao}?SendNotification=${EnabledNotification}`
        : `${EditContemplacao}?SendNotification=${EnabledNotification}`;

    const { data } = yield call(baseUrl.post, endPoint, values);

    if (data.StatusCode === 200) {
      yield put(formContemplacaoSuccess(data));
    }
    if (data.StatusCode === 400) {
      yield put(formContemplacaoFailure(data.StatusDescription));
    }
  } catch (err) {}
}

function* loadPropostasOptions({ payload }) {
  const { contratoSearchInput } = payload;
  const { data } = yield call(
    baseUrl.get,
    `${LoadPropostasOptions}?search=${contratoSearchInput}`
  );
  const propostasOptions = [];

  if (data && data.Data.length > 0) {
    data.Data.map((item) =>
      propostasOptions.push({
        label: item.name,
        value: item.id,
        empresa: item.empresa,
      })
    );
  }

  yield put(loadPropostaOptionsSuccess({ propostasOptions }));
}

function* loadAssemblyOptions({ payload }) {
  const { propostaId } = payload;

  const { data } = yield call(
    baseUrl.get,
    `${LoadAssemblyOptions}?propostaId=${propostaId}`
  );
  const assemblyOptions = [];

  if (data && data.Data.length > 0) {
    data.Data.map((item) =>
      assemblyOptions.push({ label: item.name, value: item.id })
    );
  }

  yield put(loadAssemblyOptionsSuccess({ assemblyOptions }));
}

function* getData({ payload }) {
  const { id } = payload;
  const { data } = yield call(baseUrl.get, `${GetData}?id=${id}`);

  yield put(getDataSuccess({ data }));
}

export default function* rootSagaContemplacao() {
  yield all([
    fork(watchContemplacao),
    fork(watchloadPropostasContemplacao),
    fork(watchAssemblyContemplacao),
    fork(watchGetData),
    fork(watchformContemplacaoRequest),
    fork(watchDeleteContemplacaoProposta),
  ]);
}
