import {
  CONTEMPLACAO_REQUEST,
  CONTEMPLACAO_FAILURE,
  CONTEMPLACAO_SUCCESS,
  CONTEMPLACAO_FILTER_SEARCH,
  CONTEMPLACAO_FILTER_SEARCH_INPUT,
  CREATE_CONTEMPLACAO_REQUEST,
  CREATE_CONTEMPLACAO_SUCCESS,
  PROPOSTAS_OPTIONS_REQUEST,
  PROPOSTAS_OPTIONS_SUCCESS,
  ASSEMBLY_OPTIONS_REQUEST,
  ASSEMBLY_OPTIONS_SUCCESS,
  EDIT_CONTEMPLACAO_REQUEST,
  EDIT_CONTEMPLACAO_SUCCESS,
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS,
  CREATE_CONTEMPLACAO_FAILURE,
  PAGINACAO_CONTEMPLACAO,
  CONTEMPLACAO_UPDATE_FILTER,
  DELETE_CONTEMPLACAO_PROPOSTA_SUCCESS,
  DELETE_CONTEMPLACAO_PROPOSTA_REQUEST,
  DELETE_CONTEMPLACAO_PROPOSTA_FAILURE,
  DELETE_CONTEMPLACAO_PROPOSTA_CLEAR,
  FORM_CONTEMPLACAO_REQUEST,
  FORM_CONTEMPLACAO_RETURN_SUCCESS,
  FORM_CONTEMPLACAO_RETURN_FAILURE,
} from "../actions";

const contemplacaoRequest = ({ filter }) => ({
  type: CONTEMPLACAO_REQUEST,
  payload: { filter },
});

const contemplacaoSuccess = ({
  Items,
  iTotalRecords,
  iTotalDisplayRecords,
}) => ({
  type: CONTEMPLACAO_SUCCESS,
  payload: { Items, iTotalRecords, iTotalDisplayRecords },
});

const contemplacaoFailure = () => ({ type: CONTEMPLACAO_FAILURE });
const contemplacaoUpdateFilter = ({ filter }) => ({
  type: CONTEMPLACAO_UPDATE_FILTER,
  payload: { filter },
});

const formContemplacaoRequest = (payload) => ({
  type: FORM_CONTEMPLACAO_REQUEST,
  payload: payload,
});

const formContemplacaoSuccess = (payload) => ({
  type: FORM_CONTEMPLACAO_RETURN_SUCCESS,
  payload: payload,
});

const formContemplacaoFailure = (payload) => ({
  type: FORM_CONTEMPLACAO_RETURN_FAILURE,
  payload: payload,
});

const loadPropostaOptionsRequest = ({ contratoSearchInput }) => ({
  type: PROPOSTAS_OPTIONS_REQUEST,
  payload: { contratoSearchInput },
});

const loadPropostaOptionsSuccess = ({ propostasOptions }) => ({
  type: PROPOSTAS_OPTIONS_SUCCESS,
  payload: { propostasOptions },
});

const loadAssemblyOptionsRequest = (propostaId) => ({
  type: ASSEMBLY_OPTIONS_REQUEST,
  payload: { propostaId },
});

const loadAssemblyOptionsSuccess = ({ assemblyOptions }) => ({
  type: ASSEMBLY_OPTIONS_SUCCESS,
  payload: { assemblyOptions },
});

const getDataRequest = (id) => ({
  type: GET_DATA_REQUEST,
  payload: { id },
});

const getDataSuccess = ({ data }) => ({
  type: GET_DATA_SUCCESS,
  payload: { data },
});

const deleteContemplacaoPropostaRequest = ({ id }) => ({
  type: DELETE_CONTEMPLACAO_PROPOSTA_REQUEST,
  payload: { id },
});

const deleteContemplacaoPropostaClearInformation = () => ({
  type: DELETE_CONTEMPLACAO_PROPOSTA_CLEAR,
});

const deleteContemplacaoPropostaSuccess = ({
  success,
  status_code,
  http_messages,
}) => ({
  type: DELETE_CONTEMPLACAO_PROPOSTA_SUCCESS,
  payload: { success, status_code, http_messages },
});

const deleteContemplacaoPropostaFailure = ({
  error,
  status_code,
  http_messages,
}) => ({
  type: DELETE_CONTEMPLACAO_PROPOSTA_FAILURE,
  payload: { error, status_code, http_messages },
});

export {
  contemplacaoRequest,
  contemplacaoSuccess,
  contemplacaoFailure,
  contemplacaoUpdateFilter,
  loadPropostaOptionsRequest,
  loadPropostaOptionsSuccess,
  loadAssemblyOptionsRequest,
  loadAssemblyOptionsSuccess,
  getDataRequest,
  getDataSuccess,
  deleteContemplacaoPropostaRequest,
  deleteContemplacaoPropostaSuccess,
  deleteContemplacaoPropostaFailure,
  deleteContemplacaoPropostaClearInformation,
  formContemplacaoRequest,
  formContemplacaoSuccess,
  formContemplacaoFailure,
};
