import { all } from "redux-saga/effects";

import authSagas from "./auth/saga";
import propostasSagas from "./propostas/saga";
import empresasSagas from "./empresas/saga";
import reportSagas from "./report/saga";
import cepSagas from "./cep/saga";
import propostasReativarCotaSagas from "./proposta-reativar-cota/saga";
import contemplacaoSagas from "./contemplacao/saga";
import settingsSaga from "./settings/saga";
import bacenSaga from "./bacen/saga";
import transferirVendedor from "./transferir-vendedor/saga";

export default function* rootSaga() {
  return yield all([
    authSagas(),
    propostasSagas(),
    empresasSagas(),
    reportSagas(),
    cepSagas(),
    propostasReativarCotaSagas(),
    contemplacaoSagas(),
    settingsSaga(),
    bacenSaga(),
    transferirVendedor(),
  ]);
}
