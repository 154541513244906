import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { SEND_CHECAGEM_BACEN } from "../actions";
import { sendChecagemBacenResponse } from "./actions";
import { baseUrl } from "../../../services";
import { ChecagemBacen } from "../../../constants/endPointValues";
function* watchSendChecagemBacen() {
  yield takeEvery(SEND_CHECAGEM_BACEN, sendRequestChecagemBacen);
}

function* sendRequestChecagemBacen({ payload }) {
  const { data } = payload;

  if (!data.BacenPropostaID) {
    yield put(
      sendChecagemBacenResponse({
        error: true,
        http_status: 400,
        http_messages: ["Forneça o id da propostas"],
        success: false,
      })
    );
  }

  try {
    const response = yield call(baseUrl.post, ChecagemBacen, data);

    yield put(
      sendChecagemBacenResponse({
        error: false,
        http_status: response.data.StatusCode,
        http_messages: [response.data.StatusDescription],
        success: true,
      })
    );
  } catch (ex) {
    const { Message } = JSON.parse(ex.request.response);
    yield put(
      sendChecagemBacenResponse({
        error: true,
        http_status: 400,
        http_messages: [Message],
        success: false,
      })
    );
  }
}

export default function* rootSagas() {
  yield all([fork(watchSendChecagemBacen)]);
}
