import {
  TRANSFERIR_PROPOSTA_CHECK_URL_FAILURE,
  TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST,
  TRANSFERIR_PROPOSTA_CHECK_URL_SUCCESS,
  TRANSFERIR_PROPOSTA_CLEAR_VALUES,
  TRANSFERIR_PROPOSTA_VENDEDOR_CLEAR_INFORMATION,
  TRANSFERIR_PROPOSTA_VENDEDOR_FAILURE,
  TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST,
  TRANSFERIR_PROPOSTA_VENDEDOR_SUCCESS,
} from "../actions";

const INIT_STATE = {
  error: false,
  success: false,
  http_response_status: 0,
  hasError: false,
  message: "",
  transfer_url: "",
  loading_check_url: false,
  loading_transfer_vendedor: false,
  url_responsavel: "",
  transfer_success: false,
  transfer_error: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TRANSFERIR_PROPOSTA_CHECK_URL_FAILURE:
      const { http_response_status } = action.payload;
      return {
        ...state,
        error: true,
        success: false,
        http_response_status,
        hasError: true,
        loading_check_url: false,
        url_responsavel: "",
      };
    case TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST:
      return {
        ...state,
        loading_check_url: true,
        message: "",
        hasError: false,
        success: false,
        error: false,
        transfer_url: action.payload.transfer_url,
      };
    case TRANSFERIR_PROPOSTA_CHECK_URL_SUCCESS:
      return {
        ...state,
        error: false,
        success: true,
        http_response_status: 200,
        hasError: false,
        loading_check_url: false,
        url_responsavel: action.payload.url_responsavel,
      };
    case TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST:
      return { ...state, loading_transfer_vendedor: true };
    case TRANSFERIR_PROPOSTA_VENDEDOR_SUCCESS:
      return {
        ...state,
        loading_transfer_vendedor: false,
        transfer_success: true,
        transfer_error: false,
        message: action.payload.message,
      };
    case TRANSFERIR_PROPOSTA_VENDEDOR_FAILURE:
      return {
        ...state,
        loading_transfer_vendedor: false,
        transfer_success: false,
        transfer_error: true,
        message: action.payload.message,
      };
    case TRANSFERIR_PROPOSTA_VENDEDOR_CLEAR_INFORMATION:
      return {
        ...state,
        message: "",
        transfer_url: "",
        url_responsavel: "",
        transfer_success: false,
        transfer_error: false,
      };
    case TRANSFERIR_PROPOSTA_CLEAR_VALUES:
      return {
        error: false,
        success: false,
        http_response_status: 0,
        hasError: false,
        message: "",
        transfer_url: "",
        loading_check_url: false,
        loading_transfer_vendedor: false,
        url_responsavel: "",
        transfer_success: false,
        transfer_error: false,
      };
    default:
      return { ...state };
  }
};

export default reducer;
