import {
  CLEAR_FILE_AFTER_DOWNLOAD,
  GERAR_RELATORIO_FAILURE,
  GERAR_RELATORIO_REQUEST,
  GERAR_RELATORIO_SUCCESS,
  REPORT_OBTER_FILTRO_FAILURE,
  REPORT_OBTER_FILTRO_REQUEST,
  REPORT_OBTER_FILTRO_SUCCESS,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_FAILURE,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_SUCCESS,
} from "../actions";

//** Obter filtro para o selects */
export const oberFiltrosRequest = () => ({
  type: REPORT_OBTER_FILTRO_REQUEST,
});

export const oberFiltrosSuccess = (report) => ({
  type: REPORT_OBTER_FILTRO_SUCCESS,
  payload: report,
});

export const oberFiltrosFailure = () => ({
  type: REPORT_OBTER_FILTRO_FAILURE,
});

//** Conforme o filtro no select de empresa ele faz request para obter a lista de relatorios permitidos */
export const obterRelatoriosPorEmpresasRequest = (empresas) => ({
  type: REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST,
  payload: { empresas },
});

export const obterRelatoriosPorEmpresasSuccess = (reportSelect) => ({
  type: REPORT_OBTER_RELATORIO_POR_EMPRESAS_SUCCESS,
  payload: reportSelect,
});

export const obterRelatoriosPorEmpresasFailure = () => ({
  type: REPORT_OBTER_RELATORIO_POR_EMPRESAS_FAILURE,
});

//** Consumindo a api de geração de relatório */
export const gerarRelatorioRequest = (filtroRelatorio) => ({
  type: GERAR_RELATORIO_REQUEST,
  payload: { filtroRelatorio },
});

export const gerarRelatorioSuccess = (link) => ({
  type: GERAR_RELATORIO_SUCCESS,
  payload: { link },
});

export const gerarRelatorioFailure = (filtroRelatorio) => ({
  type: GERAR_RELATORIO_FAILURE,
});

export const clearFileDownloaded = () => ({ type: CLEAR_FILE_AFTER_DOWNLOAD });
