import {
  CONTEMPLACAO_REQUEST,
  CONTEMPLACAO_FAILURE,
  CONTEMPLACAO_SUCCESS,
  CONTEMPLACAO_UPDATE_FILTER,
  CONTEMPLACAO_FILTER_SEARCH,
  CONTEMPLACAO_FILTER_SEARCH_INPUT,
  CREATE_CONTEMPLACAO_REQUEST,
  CREATE_CONTEMPLACAO_SUCCESS,
  CREATE_CONTEMPLACAO_FAILURE,
  PROPOSTAS_OPTIONS_REQUEST,
  PROPOSTAS_OPTIONS_SUCCESS,
  ASSEMBLY_OPTIONS_REQUEST,
  ASSEMBLY_OPTIONS_SUCCESS,
  EDIT_CONTEMPLACAO_REQUEST,
  EDIT_CONTEMPLACAO_SUCCESS,
  GET_DATA_REQUEST,
  GET_DATA_SUCCESS,
  PAGINACAO_CONTEMPLACAO,
  DELETE_CONTEMPLACAO_PROPOSTA_SUCCESS,
  DELETE_CONTEMPLACAO_PROPOSTA_REQUEST,
  DELETE_CONTEMPLACAO_PROPOSTA_FAILURE,
  DELETE_CONTEMPLACAO_PROPOSTA_CLEAR,
  FORM_CONTEMPLACAO_REQUEST,
  FORM_CONTEMPLACAO_RETURN_SUCCESS,
  FORM_CONTEMPLACAO_RETURN_FAILURE,
} from "../actions";

const INIT_STATE = {
  loading: false,
  success: false,
  error: false,
  filter: {
    iDisplayStart: 0,
    iDisplayLength: 20,
    iTotalRecords: 20,
    iTotalDisplayRecords: 0,
    Search: "",
    EmpresaID: 0,
    iSortOrder: 0,
  },
  deleteModal: {
    loading_delete: false,
    error: false,
    success: false,
    http_messages: [],
    status_code: 0,
  },
  Items: [],
  propostasOptions: [],
  assemblyOptions: [],
  contratoSearchInput: "",
  // propostaId: 0,
  // search: "",
  data: {},
  id: 0,
  propostaContemplacao: [],
  notification: false,
  success: false,
  error: false,
  http_messages: "",
  error_msg: "",
  iTotalRecords: 0,
  iTotalDisplayRecords: 20,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CONTEMPLACAO_REQUEST:
      return { ...state, loading: true, filter: action.payload.filter };
    case CONTEMPLACAO_FAILURE:
      return { ...state, loading: false };
    case CONTEMPLACAO_SUCCESS:
      return {
        ...state,
        loading: false,
        Items: action.payload.Items,
        iTotalRecords: action.payload.iTotalRecords,
        iTotalDisplayRecords: action.payload.iTotalDisplayRecords,
      };
    case CONTEMPLACAO_UPDATE_FILTER:
      const {
        filter: {
          currentPage,
          searchInput,
          selectedPageSize,
          iTotalRecords,
          iTotalDisplayRecords,
        },
      } = action.payload;
      return {
        ...state,
        filter: {
          ...state.filter,
          iDisplayStart: currentPage - 1,
          Search: searchInput,
          iDisplayLength: selectedPageSize,
          iTotalRecords,
          iTotalDisplayRecords,
        },
      };
    case FORM_CONTEMPLACAO_REQUEST:
      return { ...state, loading: true };
    case FORM_CONTEMPLACAO_RETURN_SUCCESS:
      return {
        ...state,
        error: false,
        loading: false,
        http_messages: "Ação realizada com sucesso!",
      };
    case FORM_CONTEMPLACAO_RETURN_FAILURE:
      return {
        ...state,
        error: true,
        loading: false,
        http_messages: action.payload,
      };
    case PROPOSTAS_OPTIONS_REQUEST:
      return {
        ...state,
        contratoSearchInput: action.payload.contratoSearchInput,
      };
    case PROPOSTAS_OPTIONS_SUCCESS:
      return { ...state, propostasOptions: action.payload.propostasOptions };
    case ASSEMBLY_OPTIONS_REQUEST:
      return { ...state, propostaId: action.payload.propostaId };
    case ASSEMBLY_OPTIONS_SUCCESS:
      return { ...state, assemblyOptions: action.payload.assemblyOptions };
    case GET_DATA_REQUEST:
      return { ...state };
    case GET_DATA_SUCCESS:
      return { ...state, data: action.payload.data };
    case DELETE_CONTEMPLACAO_PROPOSTA_CLEAR:
      return {
        ...state,
        deleteModal: {
          loading_delete: false,
          error: false,
          success: false,
          http_messages: [],
          status_code: 0,
        },
      };
    case DELETE_CONTEMPLACAO_PROPOSTA_REQUEST:
      return {
        ...state,
        deleteModal: {
          error: false,
          success: false,
          http_messages: [],
          loading_delete: true,
          status_code: 0,
        },
      };
    case DELETE_CONTEMPLACAO_PROPOSTA_SUCCESS:
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          success: action.payload.success,
          status_code: action.payload.status_code,
          http_messages: action.payload.http_messages,
          error: false,
          loading_delete: false,
        },
      };
    case DELETE_CONTEMPLACAO_PROPOSTA_FAILURE:
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          success: false,
          status_code: action.payload.status_code,
          http_messages: action.payload.http_messages,
          error: action.payload.error,
          loading_delete: false,
        },
      };
    default:
      return { ...state };
  }
};

export default reducer;
