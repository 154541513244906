/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";
export const USER_CHANGE_IMAGE = "USER_CHANGE_IMAGE";
export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_RESULT = "USER_UPDATE_RESULT";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";

/* PROPOSTAS */
export const PROPOSTAS_COUNT_REQUEST = "PROPOSTAS_COUNT_REQUEST";
export const PROPOSTAS_COUNT_SUCCESS = "PROPOSTAS_COUNT_SUCCESS";
export const PROPOSTAS_COUNT_FAILURE = "PROPOSTAS_COUNT_FAILURE";
export const PROPOSTAS_REQUEST = "PROPOSTAS_REQUEST";
export const PROPOSTAS_SUCCESS = "PROPOSTAS_SUCCESS";
export const PROPOSTAS_FAILURE = "PROPOSTAS_FAILURE";
export const PROPOSTAS_DASHBOARD_REQUEST = "PROPOSTAS_DASHBOARD_REQUEST";
export const PROPOSTAS_DASHBOARD_SUCCESS = "PROPOSTAS_DASHBOARD_SUCCESS";
export const PROPOSTAS_DASHBOARD_FAILURE = "PROPOSTAS_DASHBOARD_FAILURE";
export const PROPOSTAS_PAGINACAO = "PROPOSTAS_PAGINACAO";
export const PROPOSTAS_PAGESIZE = "PROPOSTAS_PAGESIZE";
export const PROPOSTAS_FILTRO_EMPRESA = "PROPOSTAS_FILTRO_EMPRESA";
export const PROPOSTAS_FILTRO_STATUS = " PROPOSTAS_FILTRO_STATUS";
export const PROPOSTAS_FILTRO_SEARCH = " PROPOSTAS_FILTRO_SEARCH";
export const PROPOSTAS_FILTRO_DATA = "PROPOSTAS_FILTRO_DATA";
export const HISTORICO_PROPOSTA_SUCESS = "HISTORICO_PROPOSTA_SUCESS";
export const HISTORICO_PROPOSTA_REQUEST = "HISTORICO_PROPOSTA_REQUEST";
export const OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST =
  "OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST";
export const OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_SUCESS =
  "OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_SUCESS";
export const OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_FAILURE =
  "OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_FAILURE";
export const OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_CLEAR =
  "OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_CLEAR";
export const EDITAR_PROPOSTA_REQUEST = "EDITAR_PROPOSTA_REQUEST";
export const EDITAR_PROPOSTA_SUCESS = "EDITAR_PROPOSTA_SUCESS";
export const EDITAR_PROPOSTA_FAILURE = "EDITAR_PROPOSTA_FAILURE";
export const EDITAR_PROPOSTA_CLEAR = "EDITAR_PROPOSTA_CLEAR";
export const TRANSFERIR_PROPOSTA_REQUEST = "TRANSFERIR_PROPOSTA_REQUEST";
export const TRANSFERIR_PROPOSTA_SUCESS = "TRANSFERIR_PROPOSTA_SUCESS";
export const TRANSFERIR_PROPOSTA_FAILURE = "TRANSFERIR_PROPOSTA_FAILURE";
export const TRANSFERIR_PROPOSTA_CLEAR = "TRANSFERIR_PROPOSTA_CLEAR";
export const MULTIPLAS_PROPOSTAS_REQUEST = "MULTIPLAS_PROPOSTAS_REQUEST";
export const MULTIPLAS_PROPOSTAS_SUCCESS = "MULTIPLAS_PROPOSTAS_SUCCESS";
export const MULTIPLAS_PROPOSTAS_FAILURE = "MULTIPLAS_PROPOSTAS_FAILURE";

/** constantes do reativar cota */
/** constantes usadas nas actions e reducer do proposta-reativar-cota */
export const PROPOSTA_REATIVAR_COTA_REQUEST = "PROPOSTA_REATIVAR_COTA_REQUEST";
export const PROPOSTA_REATIVAR_COTA_SUCCESS = "PROPOSTA_REATIVAR_COTA_SUCCESS";
export const PROPOSTA_REATIVAR_COTA_FAILURE = "PROPOSTA_REATIVAR_COTA_FAILURE";
export const PROPOSTA_REATIVAR_COTA_CLEAR_ =
  "PROPOSTA_REATIVAR_COTA_CLEAR_PROPOSTA";

/* EMPRESAS */
export const EMPRESAS_PERMISSOES_REQUEST = "EMPRESAS_PERMISSOES_REQUEST";
export const EMPRESAS_PERMISSOES_SUCCESS = "EMPRESAS_PERMISSOES_SUCCESS";
export const EMPRESAS_PERMISSOES_FAILURE = "EMPRESAS_PERMISSOES_FAILURE";
export const EMPRESA_LOGO = "EMPRESA_LOGO";
export const EMPRESAS_PERMISSOES_COUNT = "EMPRESAS_PERMISSOES_COUNT";

/* PROPOSTAS DASHBOARD */
export const PROPOSTAS_TOTAL_MONEY_FINALIZADAS =
  "PROPOSTAS_TOTAL_MONEY_FINALIZADAS";
export const PROPOSTAS_TOTAL_MONEY_FINALIZADAS_FAILURE =
  "PROPOSTAS_TOTAL_MONEY_FINALIZADAS_FAILURE";
export const GET_LIST_COUNT_PROPOSTAS_SUCCESS =
  "GET_LIST_COUNT_PROPOSTAS_SUCCESS";
export const GET_LIST_COUNT_PROPOSTAS_FAILURE =
  "GET_LIST_COUNT_PROPOSTAS_FAILURE";
export const LIST_MONTHLY_SALES_SUCCESS = "LIST_MONTHLY_SALES_SUCCESS";
export const LIST_MONTHLY_SALES_FAILURE = "LIST_MONTHLY_SALES_FAILURE";
export const TOTAL_SALES_BY_CATEGORIES_SUCCESS =
  "TOTAL_SALES_BY_CATEGORIES_SUCCESS";
export const TOTAL_SALES_BY_CATEGORIES_FAILURE =
  "TOTAL_SALES_BY_CATEGORIES_FAILURE";
export const TOTAL_SALES_BY_GROUP_SUCCESS = "TOTAL_SALES_BY_GROUP_SUCCESS";
export const TOTAL_SALES_BY_GROUP_FAILURE = "TOTAL_SALES_BY_GROUP_FAILURE";
export const GET_LIST_FLOW_SALES_SUCCESS = "GET_LIST_FLOW_SALES_SUCCESS";
export const GET_LIST_FLOW_SALES_FAILURE = "GET_LIST_FLOW_SALES_FAILURE";
export const LIST_METAS_VENDAS_BY_COMPANIES_SUCCESS =
  "LIST_METAS_VENDAS_BY_COMPANIES_SUCCESS";
export const LIST_METAS_VENDAS_BY_COMPANIES_FAILURE =
  "LIST_METAS_VENDAS_BY_COMPANIES_FAILURE";

/** REPORT ACTIONS STRING */
export const REPORT_OBTER_FILTRO_REQUEST = "REPORT_OBTER_FILTRO_REQUEST";
export const REPORT_OBTER_FILTRO_SUCCESS = "REPORT_OBTER_FILTRO_SUCCESS";
export const REPORT_OBTER_FILTRO_FAILURE = "REPORT_OBTER_FILTRO_FAILURE";
export const REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST =
  "REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST";
export const REPORT_OBTER_RELATORIO_POR_EMPRESAS_SUCCESS =
  "REPORT_OBTER_RELATORIO_POR_EMPRESAS_SUCCESS";
export const REPORT_OBTER_RELATORIO_POR_EMPRESAS_FAILURE =
  "REPORT_OBTER_RELATORIO_POR_EMPRESAS_FAILURE";
export const GERAR_RELATORIO_REQUEST = "GERAR_RELATORIO_REQUEST";
export const GERAR_RELATORIO_SUCCESS = "GERAR_RELATORIO_SUCCESS";
export const GERAR_RELATORIO_FAILURE = "GERAR_RELATORIO_FAILURE";
export const CLEAR_FILE_AFTER_DOWNLOAD = "CLEAR_FILE_AFTER_DOWNLOAD";

/** CEP ACTIONS STRING */
export const CONSULT_CEP_REQUEST = "CONSULT_CEP_REQUEST";
export const CONSULT_CEP_SUCCESS = "CONSULT_CEP_SUCCESS";
export const CONSULT_CEP_FAILURE = "CONSULT_CEP_FAILURE";
export const CONSULT_CEP_CLEAR = "CONSULT_CEP_FAILURE";
export const UPDATE_CEP = "UPDATE_CEP";

/** CONTEMPLACAO */
export const CONTEMPLACAO_REQUEST = "CONTEMPLACAO_REQUEST";
export const CONTEMPLACAO_FAILURE = "CONTEMPLACAO_FAILURE";
export const CONTEMPLACAO_SUCCESS = "CONTEMPLACAO_SUCCESS";
export const CONTEMPLACAO_UPDATE_FILTER = "CONTEMPLACAO_UPDATE_FILTER";
export const PROPOSTAS_OPTIONS_REQUEST = "PROPOSTAS_OPTIONS_REQUEST";
export const PROPOSTAS_OPTIONS_SUCCESS = "PROPOSTAS_OPTIONS_SUCCESS";
export const ASSEMBLY_OPTIONS_REQUEST = "ASSEMBLY_OPTIONS_REQUEST";
export const ASSEMBLY_OPTIONS_SUCCESS = "ASSEMBLY_OPTIONS_SUCCESS";
export const GET_DATA_REQUEST = "GET_DATA_REQUEST";
export const GET_DATA_SUCCESS = "GET_DATA_SUCCESS";
export const FORM_CONTEMPLACAO_REQUEST = "FORM_CONTEMPLACAO_REQUEST";
export const FORM_CONTEMPLACAO_RETURN_SUCCESS =
  "FORM_CONTEMPLACAO_RETURN_SUCCESS";
export const FORM_CONTEMPLACAO_RETURN_FAILURE =
  "FORM_CONTEMPLACAO_RETURN_FAILURE";
export const DELETE_CONTEMPLACAO_PROPOSTA_REQUEST =
  "DELETE_CONTEMPLACAO_PROPOSTA_REQUEST";
export const DELETE_CONTEMPLACAO_PROPOSTA_SUCCESS =
  "DELETE_CONTEMPLACAO_PROPOSTA_SUCCESS";
export const DELETE_CONTEMPLACAO_PROPOSTA_FAILURE =
  "DELETE_CONTEMPLACAO_PROPOSTA_FAILURE";
export const DELETE_CONTEMPLACAO_PROPOSTA_CLEAR =
  "DELETE_CONTEMPLACAO_PROPOSTA_CLEAR";

/** Checagem Bacen */
export const SEND_CHECAGEM_BACEN = "SEND_CHECAGEM_BACEN";
export const SEND_CHECAGEM_BACEN_RESPONSE = "SEND_CHECAGEM_BACEN_RESPONSE";
export const CLEAR_CHECAGEM_BACEN = "CLEAR_CHECAGEM_BACEN";

/** Transferir proposta para vendedor */
export const TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST =
  "TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST";
export const TRANSFERIR_PROPOSTA_VENDEDOR_SUCCESS =
  "TRANSFERIR_PROPOSTA_VENDEDOR_SUCCESS";
export const TRANSFERIR_PROPOSTA_VENDEDOR_FAILURE =
  "TRANSFERIR_PROPOSTA_VENDEDOR_FAILURE";
export const TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST =
  "TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST";
export const TRANSFERIR_PROPOSTA_CHECK_URL_SUCCESS =
  "TRANSFERIR_PROPOSTA_CHECK_URL_SUCCESS";
export const TRANSFERIR_PROPOSTA_CHECK_URL_FAILURE =
  "TRANSFERIR_PROPOSTA_CHECK_URL_FAILURE";
export const TRANSFERIR_PROPOSTA_CLEAR_VALUES =
  "TRANSFERIR_PROPOSTA_CLEAR_VALUES";
export const TRANSFERIR_PROPOSTA_VENDEDOR_CLEAR_INFORMATION =
  "TRANSFERIR_PROPOSTA_VENDEDOR_CLEAR_INFORMATION";

export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./propostas/actions";
export * from "./report/actions";
export * from "./cep/actions";
export * from "./contemplacao/actions";
