import { all, fork, takeEvery, put, call } from "@redux-saga/core/effects";
import { GetPermissoesEmpresas } from "../../../constants/endPointValues";
import { baseUrl } from "../../../services";
import { EMPRESAS_PERMISSOES_REQUEST } from "../actions";
import {
  empresasPermissoesCount,
  empresasPermissoesFailure,
  empresasPermissoesLogo,
  empresasPermissoesSuccess,
} from "./actions";
import headerLogoCoimexcon from "../../../assets/logos/LOGO-COIMEX-400px.png";

export function* watchEmpresaPermissoes() {
  yield takeEvery(EMPRESAS_PERMISSOES_REQUEST, getPermissoesEmpresas);
}

function* getPermissoesEmpresas() {
  try {
    const { data } = yield call(baseUrl.get, GetPermissoesEmpresas);
    // set data em empresas permissões
    yield put(empresasPermissoesSuccess(data));
    // set números de empresas
    yield put(empresasPermissoesCount(data.length));

    // verificação da imagem que vai ser disponibilizada
    data.length === 1
      ? yield put(empresasPermissoesLogo(data[0].logoLink))
      : yield put(empresasPermissoesLogo(headerLogoCoimexcon));
  } catch (ex) {
    yield put(empresasPermissoesFailure(true, ex.response.data));
  }
}

export default function* rootSaga() {
  yield all([fork(watchEmpresaPermissoes)]);
}
