export const AlphabeticalOrderArray = (nome, array) => {
  var newArray = [];

  if (!nome || nome === undefined) {
    return newArray;
  }

  newArray = array.sort((a, b) => {
    const nameA = a[nome].toLowerCase();
    const nameB = b[nome].toLowerCase();
    if (nameA < nameB) return -1;

    if (nameA > nameB) return 1;

    return 0;
  });

  return newArray;
};

export const OrderVerificationArray = (array) => {
  var newArray = [];

  newArray = array.sort((a, b) => {
    const positionA = a["TelaPosicao"];
    const positionB = b["TelaPosicao"];

    const colunaPosicaoA = a["ColunaPosicao"];
    const colunaPosicaoB = b["ColunaPosicao"];

    const colunaOrdemA = a["ColunaOrdem"];
    const colunaOrdemB = b["ColunaOrdem"];

    if (positionA < positionB) {
      return -1;
    }

    if (positionA > positionB && colunaPosicaoA > colunaPosicaoB) return 1;

    return 0;
  });

  return newArray;
};

/**
 *
 * @param {*} error = espera a exception no try/catch ou reject da Promise
 * @param {} custom = Booleano - por padrão é falso, se true passe a messages e statusCode
 * @param {} messages  = opcional
 * @param {} statusCode = opcional
 * @returns
 */
export const handlerStatusHttpError = (
  error,
  custom = false,
  messages = {},
  statusCode = 400
) => {
  let messagesReturned = {
    statusCode: statusCode,
    error_message: "",
    server_message: messages,
  };

  if (!custom) {
    if (error && error.request) {
      const { request } = error;
      // pegando mensagem de erro
      if (request.status === 401) {
        messagesReturned.statusCode = 401;
        messagesReturned.error_message = "Usuário não autorizado!";
      } else if (request.status === 400) {
        messagesReturned.statusCode = 400;
        messagesReturned.error_message =
          "Ocorreu um erro ao tentar acessar esse recurso.";
      } else if (request.status === 404) {
        messagesReturned.statusCode = 404;
        messagesReturned.error_message = "Recurso não encontrado.";
      } else if (request.status === 500) {
        messagesReturned.statusCode = 500;
        messagesReturned.error_message =
          "Ocorreu um erro inesperado. Entre em contato com o suporte.";
      }

      if (request.response) {
        const message = JSON.parse(request.response);
        messagesReturned.server_message = message;
      }
    }
  }

  return messagesReturned;
};
