import { User } from "../models/User";

export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1660;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "pt";
export const localeOptions = [
  { id: "en", name: "English - LTR", direction: "ltr" },
  { id: "es", name: "Español", direction: "ltr" },
  { id: "enrtl", name: "English - RTL", direction: "rtl" },
  { id: "pt", name: "Português - BR", direction: "ltr" },
];

export const user = {
  id: 1,
  name: "Sarah Kortney",
  img: "/assets/img/profiles/user_profile",
  date: "Last seen today 15:24",
  userName: "Sarah Kortney",
  email: "sarah@gmail.com",
  permissoesEmpresas: [],
  // role: UserRole.Admin,
};

export const currentUser = new User(user);

export const adminRoot = "/app";
export const buyUrl = "https://1.envato.market/k4z0";
export const searchPath = `${adminRoot}/pages/miscellaneous/search`;
export const servicePath = "https://api.coloredstrategies.com";

export const themeColorStorageKey = "__theme_selected_color";
export const isMultiColorActive = true;
export const defaultColor = "light.bluenavy";
export const isDarkSwitchActive = true;
export const defaultDirection = "ltr";
export const themeRadiusStorageKey = "__theme_radius";
export const isAuthGuardActive = true;
export const colors = [
  "bluenavy",
  "blueyale",
  "blueolympic",
  "greenmoss",
  "greenlime",
  "purplemonster",
  "orangecarrot",
  "redruby",
  "yellowgranola",
  "greysteel",
];

export const localStorageAllStrings = {
  tokenStorage: "TOKEN_VENDAS",
  userStorage: "vendas_current_user",
};

export const headerLogo = {
  coimexcon:
    "https://dev.consorcio.digital/Content/images/logo-white-bg@2x.png",
};
