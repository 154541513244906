import {
  CLEAR_CHECAGEM_BACEN,
  SEND_CHECAGEM_BACEN,
  SEND_CHECAGEM_BACEN_RESPONSE,
} from "../actions";

const sendChecagemBacen = ({ data }) => ({
  type: SEND_CHECAGEM_BACEN,
  payload: { data },
});

const sendChecagemBacenResponse = ({
  error,
  success,
  http_messages,
  http_status,
}) => ({
  type: SEND_CHECAGEM_BACEN_RESPONSE,
  payload: { error, success, http_messages, http_status },
});

const clearChecagemBacenReducer = () => ({ type: CLEAR_CHECAGEM_BACEN });

export {
  sendChecagemBacen,
  sendChecagemBacenResponse,
  clearChecagemBacenReducer,
};
