import {
  CLEAR_CHECAGEM_BACEN,
  SEND_CHECAGEM_BACEN,
  SEND_CHECAGEM_BACEN_RESPONSE,
} from "../actions";

const INIT_STATE = {
  loading: false,
  error: false,
  success: false,
  http_status: 0,
  http_messages: [],
};

const reducer = (state = INIT_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_CHECAGEM_BACEN:
      return {
        http_messages: [],
        http_status: 0,
        loading: false,
        error: false,
        success: false,
      };
    case SEND_CHECAGEM_BACEN:
      return {
        http_messages: [],
        http_status: 0,
        loading: true,
        error: false,
        success: false,
      };
    case SEND_CHECAGEM_BACEN_RESPONSE:
      console.log(payload);
      return { ...payload, loading: false };
    default:
      return { ...state };
  }
};

export default reducer;
