import { takeEvery, all, fork, put, call } from "redux-saga/effects";
import { ReativarCota } from "../../../constants/endPointValues";
import { baseUrl } from "../../../services";
import { PROPOSTA_REATIVAR_COTA_REQUEST } from "../actions";
import {
  propostaReativarCotaInitialFailure,
  propostaReativarCotaInitialSuccess,
} from "./actions";

function* watchPropostaReativarCota() {
  yield takeEvery(PROPOSTA_REATIVAR_COTA_REQUEST, reativarCota);
}

function* reativarCota({ payload }) {
  try {
    if (!payload.propostaId) {
      yield put(
        propostaReativarCotaInitialFailure({
          http_response: 400,
          http_messages: ["O id dá proposta é obrigatório"],
        })
      );
    }

    const { propostaId } = payload;
    const { data } = yield call(
      baseUrl.post,
      `${ReativarCota}?propostaId=${propostaId}`
    );

    yield put(
      propostaReativarCotaInitialSuccess({
        condition: true,
        http_response: 200,
        http_messages: [data],
      })
    );
  } catch (error) {
    if (error) {
      if (error.request.status === 400) {
        const { Message } = JSON.parse(error.request.response);
        yield put(
          propostaReativarCotaInitialFailure({
            http_response: 400,
            http_messages: [Message],
          })
        );
      } else if (error.request.status === 404) {
        yield put(
          propostaReativarCotaInitialFailure({
            http_response: 404,
            http_messages: [
              "Não foi possivel concluir a ação, verifique sua internet ou se o serviço está disponivel",
            ],
          })
        );
      } else if (error.request.status === 500) {
        const { Message } = JSON.parse(error.request.response);
        yield put(
          propostaReativarCotaInitialFailure({
            http_response: 400,
            http_messages: [Message],
          })
        );
      }
    }
  }
}

export default function* rootSagaPropostaReativarCota() {
  yield all([fork(watchPropostaReativarCota)]);
}
