import {
  EMPRESAS_PERMISSOES_COUNT,
  EMPRESAS_PERMISSOES_FAILURE,
  EMPRESAS_PERMISSOES_REQUEST,
  EMPRESAS_PERMISSOES_SUCCESS,
  EMPRESA_LOGO,
} from "../actions";

export const empresasPermissoesRequest = () => ({
  type: EMPRESAS_PERMISSOES_REQUEST,
});

export const empresasPermissoesSuccess = (empresasPermissoes) => ({
  type: EMPRESAS_PERMISSOES_SUCCESS,
  payload: { empresasPermissoes },
});

export const empresasPermissoesFailure = (error, error_message) => ({
  type: EMPRESAS_PERMISSOES_FAILURE,
  payload: { error, error_message },
});

export const empresasPermissoesCount = (empresas_count) => ({
  type: EMPRESAS_PERMISSOES_COUNT,
  payload: { empresas_count },
});

export const empresasPermissoesLogo = (empresa_logo) => ({
  type: EMPRESA_LOGO,
  payload: { empresa_logo },
});
