import { all, fork, takeEvery, call, put } from "redux-saga/effects";
import {
  ObterFiltros,
  ObterRelatoriosPorEmpresas,
  GerarRelatorio,
} from "../../../constants/endPointValues";
import { baseUrl } from "../../../services";
import {
  GERAR_RELATORIO_REQUEST,
  obterRelatoriosPorEmpresasFailure,
  obterRelatoriosPorEmpresasSuccess,
  REPORT_OBTER_FILTRO_REQUEST,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST,
} from "../actions";
import {
  oberFiltrosSuccess,
  oberFiltrosFailure,
  gerarRelatorioFailure,
  gerarRelatorioSuccess,
} from "../report/actions";

function* watchObterFiltros() {
  yield takeEvery(REPORT_OBTER_FILTRO_REQUEST, obterFiltros);
}

function* watchObterFiltrosPorEmpresas() {
  yield takeEvery(
    REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST,
    obterFiltrosPorEmpresas
  );
}

function* watchGerarRelatorio() {
  yield takeEvery(GERAR_RELATORIO_REQUEST, gerarRelatorio);
}

function* obterFiltros() {
  try {
    const { data } = yield call(baseUrl.get, ObterFiltros);

    yield put(oberFiltrosSuccess(data));
  } catch (ex) {
    yield put(oberFiltrosFailure());
  }
}

function* obterFiltrosPorEmpresas({ payload }) {
  try {
    const { empresas } = payload;
    const { data } = yield call(
      baseUrl.post,
      ObterRelatoriosPorEmpresas,
      empresas
    );

    const uniqueData = [
      ...data
        .reduce((map, obj) => map.set(obj.PropostaRelatorioID, obj), new Map())
        .values(),
    ];

    yield put(obterRelatoriosPorEmpresasSuccess(uniqueData));
  } catch (ex) {
    yield put(obterRelatoriosPorEmpresasFailure());
  }
}

function* gerarRelatorio({ payload }) {
  try {
    const { filtroRelatorio } = payload;

    const { data } = yield call(baseUrl.post, GerarRelatorio, filtroRelatorio);

    yield put(gerarRelatorioSuccess(data));
  } catch (ex) {
    yield put(gerarRelatorioFailure());
  }
}

export default function* rootSagaReport() {
  yield all([
    fork(watchObterFiltros),
    fork(watchObterFiltrosPorEmpresas),
    fork(watchGerarRelatorio),
  ]);
}
