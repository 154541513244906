import {
  GET_LIST_COUNT_PROPOSTAS_FAILURE,
  GET_LIST_COUNT_PROPOSTAS_SUCCESS,
  GET_LIST_FLOW_SALES_FAILURE,
  MULTIPLAS_PROPOSTAS_REQUEST,
  MULTIPLAS_PROPOSTAS_FAILURE,
  MULTIPLAS_PROPOSTAS_SUCCESS,
  GET_LIST_FLOW_SALES_SUCCESS,
  LIST_METAS_VENDAS_BY_COMPANIES_FAILURE,
  LIST_METAS_VENDAS_BY_COMPANIES_SUCCESS,
  LIST_MONTHLY_SALES_FAILURE,
  LIST_MONTHLY_SALES_SUCCESS,
  PROPOSTAS_COUNT_FAILURE,
  PROPOSTAS_COUNT_REQUEST,
  PROPOSTAS_COUNT_SUCCESS,
  PROPOSTAS_DASHBOARD_REQUEST,
  PROPOSTAS_DASHBOARD_SUCCESS,
  PROPOSTAS_FAILURE,
  PROPOSTAS_REQUEST,
  PROPOSTAS_SUCCESS,
  PROPOSTAS_TOTAL_MONEY_FINALIZADAS,
  PROPOSTAS_TOTAL_MONEY_FINALIZADAS_FAILURE,
  TOTAL_SALES_BY_CATEGORIES_FAILURE,
  TOTAL_SALES_BY_CATEGORIES_SUCCESS,
  TOTAL_SALES_BY_GROUP_FAILURE,
  TOTAL_SALES_BY_GROUP_SUCCESS,
  PROPOSTAS_PAGINACAO,
  PROPOSTAS_PAGESIZE,
  PROPOSTAS_FILTRO_EMPRESA,
  PROPOSTAS_FILTRO_STATUS,
  PROPOSTAS_FILTRO_SEARCH,
  PROPOSTAS_FILTRO_DATA,
  HISTORICO_PROPOSTA_SUCESS,
  HISTORICO_PROPOSTA_REQUEST,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_CLEAR,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_SUCESS,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_FAILURE,
  EDITAR_PROPOSTA_REQUEST,
  EDITAR_PROPOSTA_SUCESS,
  EDITAR_PROPOSTA_FAILURE,
  EDITAR_PROPOSTA_CLEAR,
  TRANSFERIR_PROPOSTA_REQUEST,
  TRANSFERIR_PROPOSTA_SUCESS,
  TRANSFERIR_PROPOSTA_FAILURE,
  TRANSFERIR_PROPOSTA_CLEAR,
} from "../actions";

export const loadingPropostasInformation = () => ({
  type: PROPOSTAS_DASHBOARD_REQUEST,
});

export const successPropostasInformation = (propostasInformation) => ({
  type: PROPOSTAS_DASHBOARD_SUCCESS,
  payload: propostasInformation,
});

export const TotalMoneyPropostasFinalizadasSuccess = ({
  moneyPropostasFinalizadas,
}) => ({
  type: PROPOSTAS_TOTAL_MONEY_FINALIZADAS,
  payload: { moneyPropostasFinalizadas },
});

export const TotalMoneyPropostasFinalizadasFailure = () => ({
  type: PROPOSTAS_TOTAL_MONEY_FINALIZADAS_FAILURE,
});

export const successPropostasDashboard = () => ({
  type: PROPOSTAS_DASHBOARD_SUCCESS,
});

export const propostasCountRequest = (
  empresaId,
  search,
  dataInicial,
  dataFinal
) => ({
  type: PROPOSTAS_COUNT_REQUEST,
  payload: { empresaId, search, dataInicial, dataFinal },
});

export const propostasCountSuccess = (propostasCount) => ({
  type: PROPOSTAS_COUNT_SUCCESS,
  payload: { propostasCount },
});

export const GetListCountPropostasSuccess = (list) => ({
  type: GET_LIST_COUNT_PROPOSTAS_SUCCESS,
  payload: { list },
});

export const GetListCountPropostasFailure = () => ({
  type: GET_LIST_COUNT_PROPOSTAS_FAILURE,
});

export const GetListMonthlySalesSuccess = ({ propostasVendasMes }) => ({
  type: LIST_MONTHLY_SALES_SUCCESS,
  payload: { propostasVendasMes },
});

export const GetListMonthlySalesFailure = () => ({
  type: LIST_MONTHLY_SALES_FAILURE,
});

// --- Carregando o donugts de categorias
// -- Inicio
export const TotalSalesByCategoriesSuccess = ({
  categoriasPropostasVendas,
}) => ({
  type: TOTAL_SALES_BY_CATEGORIES_SUCCESS,
  payload: { categoriasPropostasVendas },
});

export const TotalSalesByCategoriesFailure = () => ({
  type: TOTAL_SALES_BY_CATEGORIES_FAILURE,
});

// -- Fim
export const propostasCountFailure = (errorMessage) => ({
  type: PROPOSTAS_COUNT_FAILURE,
  payload: { errorMessage },
});

// Carregando Total de vendas por grupo
/** Inicio */

export const TotalSalesByGroupSuccess = ({
  quantityPropostasFinalizadasByGroup,
}) => ({
  type: TOTAL_SALES_BY_GROUP_SUCCESS,
  payload: { quantityPropostasFinalizadasByGroup },
});

export const TotalSalesByGroupFailure = () => ({
  type: TOTAL_SALES_BY_GROUP_FAILURE,
});
/** Fim */

//-- Actions de carregamento do Fluxo de compras
/**Inicio */
export const GetListFlowSalesSuccess = ({ listLeads }) => ({
  type: GET_LIST_FLOW_SALES_SUCCESS,
  payload: { listLeads },
});

export const GetListFlowSalesFailure = () => ({
  type: GET_LIST_FLOW_SALES_FAILURE,
});

/**Fim */

// -- Actions de carregamento das metas
/** Inicio */
export const GetListMetasVendasByCompaniesSuccess = ({
  metasVendasByCompanies,
}) => ({
  type: LIST_METAS_VENDAS_BY_COMPANIES_SUCCESS,
  payload: { metasVendasByCompanies },
});

export const GetListMetasVendasByCompaniesFailure = () => ({
  type: LIST_METAS_VENDAS_BY_COMPANIES_FAILURE,
});

/** Fim */

export const propostasListRequest = (filter) => ({
  type: PROPOSTAS_REQUEST,
  payload: { filter },
});

export const propostasListSuccess = (data) => ({
  type: PROPOSTAS_SUCCESS,
  payload: { data },
});

export const propostasListFailure = (errorMessage, hasError) => ({
  type: PROPOSTAS_FAILURE,
  payload: { errorMessage, hasError },
});

export const propostasPaginacao = (iDisplayStart) => ({
  type: PROPOSTAS_PAGINACAO,
  payload: { iDisplayStart },
});

export const propostasPageSize = (iDisplayLength) => ({
  type: PROPOSTAS_PAGESIZE,
  payload: { iDisplayLength },
});

export const propostasFiltroEmpresa = (empresaID) => ({
  type: PROPOSTAS_FILTRO_EMPRESA,
  payload: { empresaID },
});

export const propostasFiltroStatus = (status, bacen = false) => ({
  type: PROPOSTAS_FILTRO_STATUS,
  payload: { status, bacen },
});

export const propostasFiltroSearch = (Search) => ({
  type: PROPOSTAS_FILTRO_SEARCH,
  payload: { Search },
});

export const propostasFiltroData = (DataInicial, DataFinal) => ({
  type: PROPOSTAS_FILTRO_DATA,
  payload: { DataInicial, DataFinal },
});

export const historicoPropostaRequest = ({ propostaId, page, pageSize }) => ({
  type: HISTORICO_PROPOSTA_REQUEST,
  payload: { propostaId, page, pageSize },
});

export const historicoPropostaSucess = (historico) => ({
  type: HISTORICO_PROPOSTA_SUCESS,
  payload: { historico },
});

export const obterSelectValuesPropostaFinalizadaRequest = (propostaId) => ({
  type: OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST,
  payload: { propostaId },
});

export const obterSelectValuesPropostaFinalizadaClear = () => ({
  type: OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_CLEAR,
});

export const obterSelectValuesPropostaFinalizadaSucess = ({
  Tabelas,
  Bens,
  Planos,
}) => ({
  type: OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_SUCESS,
  payload: { Tabelas, Bens, Planos },
});

export const obterSelectValuesPropostaFinalizadaFailure = () => ({
  type: OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_FAILURE,
});

/** Editar a Proposta */
export const editarPropostaRequest = ({ proposta }) => ({
  type: EDITAR_PROPOSTA_REQUEST,
  payload: { proposta },
});

export const editarPropostaSucess = () => ({
  type: EDITAR_PROPOSTA_SUCESS,
});

export const editarPropostaFailure = ({ errors_messages }) => ({
  type: EDITAR_PROPOSTA_FAILURE,
  payload: { errors_messages },
});

export const editarPropostaClear = () => ({ type: EDITAR_PROPOSTA_CLEAR });

/** Transferir a Proposta */
export const transferirPropostaRequest = ({ proposta }) => ({
  type: TRANSFERIR_PROPOSTA_REQUEST,
  payload: { proposta },
});

export const transferirPropostaSucess = () => ({
  type: TRANSFERIR_PROPOSTA_SUCESS,
});

export const transferirPropostaFailure = ({ errors_messages }) => ({
  type: TRANSFERIR_PROPOSTA_FAILURE,
  payload: { errors_messages },
});

export const transferirPropostaClear = () => ({
  type: TRANSFERIR_PROPOSTA_CLEAR,
});

export const multiplasPropostasRequest = (propostaId) => ({
  type: MULTIPLAS_PROPOSTAS_REQUEST,
  payload: { propostaId },
});

export const multiplasPropostasSuccess = (payload) => ({
  type: MULTIPLAS_PROPOSTAS_SUCCESS,
  payload: { payload },
});

export const multiplasPropostasFailure = (payload) => ({
  type: MULTIPLAS_PROPOSTAS_FAILURE,
  payload: { payload },
});
