import {
  CHANGE_LOCALE,
  USER_CHANGE_IMAGE,
  USER_UPDATE,
  USER_UPDATE_RESULT,
  USER_UPDATE_FAILURE,
} from "../actions";
import { getCurrentLanguage, getCurrentUser } from "../../../helpers/Utils";

const INIT_STATE = {
  locale: getCurrentLanguage(),
  currentUser: {
    ...getCurrentUser(),
    newPassword: "",
    confirmedPassword: "",
    password: "",
  },
  resultUpdate: { success: false, msg: "", loading: false, isModalOpen: false },
  changeImage: false,
  error_msg: "",
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.payload };
    case USER_UPDATE:
      return {
        ...state,
        currentUser: action.payload.user,
        loading: true,
        changeImage: false,
      };
    case USER_UPDATE_RESULT:
      return {
        ...state,
        resultUpdate: action.payload.resultUpdate,
        isModalOpen: true,
        loading: false,
        changeImage: false,
      };
    case USER_CHANGE_IMAGE:
      return {
        ...state,
        currentUser: { ...state.currentUser, img: action.payload.url },
        changeImage: true,
      };
    case USER_UPDATE_FAILURE:
      return { ...state, error_msg: action.payload };
    default:
      return { ...state };
  }
};

export default reducer;
