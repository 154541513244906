import {
  PROPOSTA_REATIVAR_COTA_CLEAR_,
  PROPOSTA_REATIVAR_COTA_FAILURE,
  PROPOSTA_REATIVAR_COTA_REQUEST,
  PROPOSTA_REATIVAR_COTA_SUCCESS,
} from "../actions";

/**
 * loading = capitura do request, toda vez que o request é acionado o loading é setado para true
 * success = Quando a api retorna sucesso, essa variavel é setada como true
 * error = Quando a api retorna um erro, variavel setada para true
 * error_response = pega o codigo do verbo de retorno da api
 * http_messages = retorna as messages de retorno da api
 * propostaId = a proposa atual que está sendo reativada
 */
const INIT_STATE = {
  loading: false,
  success: false,
  error: false,
  http_response: 0,
  http_messages: [],
  propostaId: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROPOSTA_REATIVAR_COTA_REQUEST:
      return { ...state, loading: true, propostaId: action.payload.propostaId };
    case PROPOSTA_REATIVAR_COTA_SUCCESS:
      return {
        ...state,
        loading: false,
        error: !action.payload.condition,
        success: action.payload.condition,
        http_response: action.payload.http_response,
        http_messages: action.payload.http_messages,
      };
    case PROPOSTA_REATIVAR_COTA_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        http_response: action.payload.http_response,
        http_messages: action.payload.http_messages,
      };
    case PROPOSTA_REATIVAR_COTA_CLEAR_:
      return {
        loading: false,
        success: false,
        error: false,
        http_response: 0,
        http_messages: [],
        propostaId: 0,
      };
    default:
      return { ...state };
  }
};
