import { getCurrentUser } from "../../../helpers/Utils";
import {
  isAuthGuardActive,
  currentUser,
} from "../../../constants/defaultValues";
import {
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
} from "../actions";

export const INIT_STATE = {
  canAccess: false,
  access_token: "",
  user: {},
  loading: false,
  token: "",
  error: "",
  currentUser: isAuthGuardActive ? getCurrentUser() : currentUser,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, error: "" };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: "",
      };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: "" };
    default:
      return { ...state };
  }
};

export default reducer;
