import { combineReducers } from "redux";

import menu from "./menu/reducer";
import settings from "./settings/reducer";
import auth from "./auth/reducer";
import propostas from "./propostas/reducer";
import propostaReativarCota from "./proposta-reativar-cota/reducer";
import empresas from "./empresas/reducer";
import report from "./report/reducer";
import cep from "./cep/reducer";
import contemplacao from "./contemplacao/reducer";
import bacen from "./bacen/reducer";
import transferirVendedor from "./transferir-vendedor/reducer";

export default combineReducers({
  auth,
  bacen,
  cep,
  empresas,
  menu,
  propostas,
  propostaReativarCota,
  report,
  settings,
  contemplacao,
  transferirVendedor,
});
