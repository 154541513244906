import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { USER_CHANGE_IMAGE, USER_UPDATE } from "../actions";
import { setCurrentUser, getCurrentUser } from "../../../helpers/Utils";
import { userUpdateResult, userChangeImage } from "./actions";
import { UserUpdate } from "../../../constants/endPointValues";
import { baseUrl } from "../../../services";
import { handlerStatusHttpError } from "../../../utils/helpers";

export function* watchUserChangeImage() {
  yield takeEvery(USER_CHANGE_IMAGE, currentUserChangeImage);
}
function currentUserChangeImage({ payload }) {
  let currentUser = getCurrentUser();
  currentUser.img = payload.url;
  setCurrentUser(currentUser);
  // console.log(currentUser.img)
}

export function* watchUserUpdate() {
  yield takeEvery(USER_UPDATE, userUpdate);
}

export default function* rootSaga() {
  yield all([fork(watchUserChangeImage), fork(watchUserUpdate)]);
}

function* userUpdate({ payload }) {
  try {
    const { user } = payload;
    const { data } = yield call(baseUrl.post, UserUpdate, user);
    let currentUser = getCurrentUser();
    currentUser = {
      ...currentUser,
      name: user.name,
      email: user.email,
      img: data.imgUrl,
    };
    setCurrentUser(currentUser);

    yield all([
      put(
        userUpdateResult({
          success: true,
          msg: "Alterações realizadas com sucesso!",
          loading: false,
          isModalOpen: true,
        })
      ),
      put(userChangeImage(data.imgUrl)),
    ]);
  } catch (ex) {
    const errorHandler = handlerStatusHttpError(ex);

    if (errorHandler.statusCode == 400) {
      if (errorHandler.server_message.Message == " Incorrect password.") {
        yield put(
          userUpdateResult({
            success: false,
            msg: "Senha atual incorreta.",
            loading: false,
            isModalOpen: false,
          })
        );
      } else {
        yield put(
          userUpdateResult({
            success: false,
            msg: errorHandler.server_message.Message,
            loading: false,
            isModalOpen: false,
          })
        );
      }
    } else {
      yield put(
        userUpdateResult({
          success: false,
          msg: errorHandler.error_message,
          loading: false,
          isModalOpen: false,
        })
      );
    }
  }
}
