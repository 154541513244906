import {
  CONSULT_CEP_FAILURE,
  CONSULT_CEP_REQUEST,
  CONSULT_CEP_SUCCESS,
  CONSULT_CEP_CLEAR,
  UPDATE_CEP,
} from "../actions";

export const consultCepRequest = ({ cep }) => ({
  type: CONSULT_CEP_REQUEST,
  payload: { cep },
});

export const consultCepClear = () => ({
  type: CONSULT_CEP_CLEAR,
});

export const consultCepSuccess = ({ response }) => ({
  type: CONSULT_CEP_SUCCESS,
  payload: { response },
});

export const consultCepFailure = () => ({
  type: CONSULT_CEP_FAILURE,
});

export const updateFields = ({ update_ }) => ({
  type: UPDATE_CEP,
  payload: { update_ },
});
