import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";

import { store } from "./store";

const App = React.lazy(() => import(/* webpackChunkName: "App" */ "./App"));

const Main = () => {
  return (
    <Provider store={store}>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </Provider>
  );
};

ReactDOM.render(<Main />, document.getElementById("root"));

reportWebVitals();
