import {
  all,
  fork,
  takeEvery,
  call,
  put,
  takeLatest,
  take,
} from "redux-saga/effects";
import {
  CountPropostas,
  GetListCountPropostas,
  GetPropostasInformation,
  ListFlowSales,
  ListMetasSales,
  ListMonthlySales,
  ListPropostas,
  TotalMoneyPropostasFinalizadas,
  TotalSalesByCategories,
  TotalSalesByGroup,
  PropostasHistorico,
  PropostaObterDadosSelectProposta,
  EditarProposta,
  TransferirProposta,
  GetMultiplasPropostas,
} from "../../../constants/endPointValues";
import { baseUrl } from "../../../services";
import {
  HISTORICO_PROPOSTA,
  PROPOSTAS_COUNT_REQUEST,
  PROPOSTAS_DASHBOARD_REQUEST,
  PROPOSTAS_REQUEST,
  HISTORICO_PROPOSTA_REQUEST,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST,
  EDITAR_PROPOSTA_REQUEST,
  TRANSFERIR_PROPOSTA_REQUEST,
  MULTIPLAS_PROPOSTAS_REQUEST,
} from "../actions";
import {
  GetListCountPropostasFailure,
  GetListCountPropostasSuccess,
  GetListFlowSalesFailure,
  multiplasPropostasSuccess,
  GetListFlowSalesSuccess,
  GetListMetasVendasByCompaniesFailure,
  GetListMetasVendasByCompaniesSuccess,
  GetListMonthlySalesFailure,
  GetListMonthlySalesSuccess,
  historicoPropostaSucess,
  propostasCountSuccess,
  successPropostasDashboard,
  successPropostasInformation,
  TotalMoneyPropostasFinalizadasFailure,
  TotalMoneyPropostasFinalizadasSuccess,
  TotalSalesByCategoriesFailure,
  TotalSalesByCategoriesSuccess,
  TotalSalesByGroupFailure,
  TotalSalesByGroupSuccess,
  obterSelectValuesPropostaFinalizadaFailure,
  obterSelectValuesPropostaFinalizadaSucess,
  editarPropostaFailure,
  editarPropostaSucess,
  transferirPropostaFailure,
  transferirPropostaSucess,
} from "./actions";
import { propostasListSuccess } from "./actions";
import { JSON_to_URLEncoded } from "../../../helpers/Utils";

export function* watchGetInformationDashboard() {
  yield takeLatest(PROPOSTAS_DASHBOARD_REQUEST, GetInformation);
}

function* GetInformation() {
  try {
    const { data } = yield call(baseUrl.get, GetPropostasInformation);
    yield put(successPropostasInformation(data));
  } catch (ex) {
    // --
    console.log(ex);
  }
}

function* watchTotalMoneyPropostas() {
  try {
    const { data } = yield call(baseUrl.get, TotalMoneyPropostasFinalizadas);

    yield put(
      TotalMoneyPropostasFinalizadasSuccess({ moneyPropostasFinalizadas: data })
    );
  } catch (ex) {
    yield put(TotalMoneyPropostasFinalizadasFailure());
  }
}

function* watchCountersStatusPropostas() {
  try {
    const { data } = yield call(baseUrl.get, GetListCountPropostas);
    yield put(GetListCountPropostasSuccess(data));
  } catch (ex) {
    yield put(GetListCountPropostasFailure());
  }
}

function* watchGetListMonthlySales() {
  try {
    const { data } = yield call(baseUrl.get, ListMonthlySales);
    yield put(GetListMonthlySalesSuccess({ propostasVendasMes: data }));
  } catch (ex) {
    yield put(GetListMonthlySalesFailure());
  }
}

//-- Carrega as informa��es do donugts
function* watchTotalSalesByCategories() {
  try {
    const { data } = yield call(baseUrl.get, TotalSalesByCategories);
    yield put(
      TotalSalesByCategoriesSuccess({ categoriasPropostasVendas: data })
    );
  } catch (ex) {
    yield put(TotalSalesByCategoriesFailure());
  }
}

// -- Carregando as informa��es da tabelas de vendas total por grupo formado
function* watchTotalSalesByGroup() {
  try {
    const { data } = yield call(baseUrl.get, TotalSalesByGroup);

    yield put(
      TotalSalesByGroupSuccess({ quantityPropostasFinalizadasByGroup: data })
    );
  } catch (ex) {
    // --
    yield put(TotalSalesByGroupFailure());
  }
}

// -- Carregando informa��es do fluxo estatistico de compras
function* watchGetListFlowSales() {
  try {
    const { data } = yield call(baseUrl.get, ListFlowSales);

    yield put(GetListFlowSalesSuccess({ listLeads: data }));
  } catch (ex) {
    //--
    yield put(GetListFlowSalesFailure());
  }
}

//-- Carregando Informa��es das metas de vendas cadastradas
function* watchListMetasVendas() {
  try {
    const { data } = yield call(baseUrl.get, ListMetasSales);

    yield put(
      GetListMetasVendasByCompaniesSuccess({ metasVendasByCompanies: data })
    );
  } catch (ex) {
    //--
    yield put(GetListMetasVendasByCompaniesFailure());
  }
}

export function* watchGetInformationPropostasCount() {
  yield takeEvery(PROPOSTAS_COUNT_REQUEST, getCountPropostasInformation);
}

function* getCountPropostasInformation({ payload }) {
  const { empresaId, search, dataInicial, dataFinal } = payload;
  const { data } = yield call(
    baseUrl.get,
    `${CountPropostas}?empresaId=${empresaId}&search=${search}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`,
    { method: "GET" }
  );
  yield put(propostasCountSuccess(data));
}

export function* watchGetListPropostas() {
  yield takeEvery(PROPOSTAS_REQUEST, GetListPropostas);
}

function* GetListPropostas({ payload }) {
  try {
    const { filter } = payload;

    const { data } = yield call(baseUrl.post, ListPropostas, filter);
    yield put(propostasListSuccess(data));
  } catch (ex) {
    // yield put(propostasListFailure(true, ex.response.data))
  }
}

export function* watchHistoricoProposta() {
  yield takeEvery(HISTORICO_PROPOSTA_REQUEST, getHistoricoProposta);
}

function* getHistoricoProposta({ payload }) {
  const { propostaId, page, pageSize } = payload;
  const { data } = yield call(
    baseUrl.get,
    `${PropostasHistorico}?propostaId=${propostaId}&page=${page}&pageSize=${pageSize}`
  );
  yield put(historicoPropostaSucess(data));
}

function* watchObterSelectValues() {
  yield takeEvery(
    OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST,
    obterSelectValues
  );
}

function* obterSelectValues({ payload }) {
  try {
    const { propostaId } = payload;

    const { data } = yield call(
      baseUrl.get,
      `${PropostaObterDadosSelectProposta}?propostaId=${propostaId}`
    );

    const { Tabelas, Bens, Planos } = data;

    yield put(
      obterSelectValuesPropostaFinalizadaSucess({ Tabelas, Bens, Planos })
    );
  } catch (ex) {
    yield put(obterSelectValuesPropostaFinalizadaFailure());
  }
}

function* watchEditarProposta() {
  yield takeEvery(EDITAR_PROPOSTA_REQUEST, editarProposta);
}

function* editarProposta({ payload }) {
  try {
    const { proposta } = payload;

    const { data } = yield call(
      baseUrl.post,
      EditarProposta,
      JSON_to_URLEncoded(proposta),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    );

    if (data.StatusCode === 400) {
      throw data.StatusDescription;
    }

    yield put(editarPropostaSucess());
  } catch (error) {
    if (error.request) {
      if (error.request.status === 500) {
        yield put(
          editarPropostaFailure({
            errors_messages: [
              "Ocorreu um erro inesperado, entre em contato com o suporte!",
            ],
          })
        );
      } else if (error.request.status === 400) {
        yield put(
          editarPropostaFailure({ errors_messages: error.request.message })
        );
      } else if (error.request.status === 404) {
        yield put(
          editarPropostaFailure({
            errors_messages: [
              "Ocorreu um erro, não foi possivel se conectar com o servidor!",
            ],
          })
        );
      } else {
        yield put(
          editarPropostaFailure({
            errors_messages: [
              "Ocorreu um erro inesperado, entre em contato com o suporte!",
            ],
          })
        );
      }
    } else {
      yield put(editarPropostaFailure({ errors_messages: [error] }));
    }
  }
}

/** Transferir proposta Request Api */
function* watchTransferirProposta() {
  yield takeEvery(TRANSFERIR_PROPOSTA_REQUEST, transferirProposta);
}

function* transferirProposta({ payload }) {
  try {
    const { proposta } = payload;

    const { data } = yield call(
      baseUrl.post,
      TransferirProposta,
      JSON_to_URLEncoded(proposta),
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
      }
    );

    yield put(transferirPropostaSucess());
  } catch (error) {
    if (error.request) {
      if (error.request.status === 500) {
        yield put(
          transferirPropostaFailure({
            errors_messages: [
              "Ocorreu um erro inesperado, entre em contato com o suporte!",
            ],
          })
        );
      } else if (error.request.status === 400) {
        yield put(
          transferirPropostaFailure({
            errors_messages: JSON.parse(error.request.response).Message,
          })
        );
      } else if (error.request.status === 404) {
        yield put(
          transferirPropostaFailure({
            errors_messages: [
              "Ocorreu um erro, não foi possivel se conectar com o servidor!",
            ],
          })
        );
      } else {
        yield put(
          transferirPropostaFailure({
            errors_messages: [
              "Ocorreu um erro inesperado, entre em contato com o suporte!",
            ],
          })
        );
      }
    } else {
      if (error.message) {
        yield put(
          transferirPropostaFailure({ errors_messages: [error.Message] })
        );
      } else {
        yield put(transferirPropostaFailure({ errors_messages: [error] }));
      }
    }
  }
}

function* watchMultiplasPropostas() {
  yield takeEvery(MULTIPLAS_PROPOSTAS_REQUEST, multiplasPropostas);
}

function* multiplasPropostas({ payload }) {
  try {
    const { propostaId } = payload;
    const { data } = yield call(
      baseUrl.get,
      `${GetMultiplasPropostas}?propostaId=${propostaId}`
    );
    yield put(multiplasPropostasSuccess({ data }));
  } catch {
    // -ex - not implemented
  }
}

export default function* rootSagaPropostas() {
  yield all([
    fork(watchGetInformationDashboard),
    fork(watchGetInformationPropostasCount),
    fork(watchGetListPropostas),
    fork(watchHistoricoProposta),
    fork(watchObterSelectValues),
    fork(watchEditarProposta),
    fork(watchTransferirProposta),
    fork(watchMultiplasPropostas),
  ]);
}
