function User(user) {
  let newUser = Object.create(userConstructor);
  newUser.id = user.id;
  newUser.name = user.name;
  newUser.img = user.img;
  newUser.date = user.date;
  newUser.userName = user.userName;
  newUser.email = user.email;
  newUser.permissoesEmpresas = user.permissoesEmpresas;
  return newUser;
}

const userConstructor = {
  get fullName() {
    return `${this.name}`;
  },
};

export { User, userConstructor };
