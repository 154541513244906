import {
  PROPOSTAS_COUNT_REQUEST,
  PROPOSTAS_COUNT_SUCCESS,
  PROPOSTAS_REQUEST,
  PROPOSTAS_SUCCESS,
  PROPOSTAS_DASHBOARD_REQUEST,
  PROPOSTAS_DASHBOARD_SUCCESS,
  PROPOSTAS_COUNT_FAILURE,
  PROPOSTAS_FAILURE,
  PROPOSTAS_TOTAL_MONEY_FINALIZADAS,
  PROPOSTAS_TOTAL_MONEY_FINALIZADAS_FAILURE,
  PROPOSTAS_PAGINACAO,
  PROPOSTAS_PAGESIZE,
  PROPOSTAS_FILTRO_EMPRESA,
  PROPOSTAS_FILTRO_STATUS,
  PROPOSTAS_FILTRO_SEARCH,
  PROPOSTAS_FILTRO_DATA,
  HISTORICO_PROPOSTA_REQUEST,
  HISTORICO_PROPOSTA_SUCESS,
  GET_LIST_COUNT_PROPOSTAS_SUCCESS,
  GET_LIST_COUNT_PROPOSTAS_FAILURE,
  LIST_MONTHLY_SALES_SUCCESS,
  LIST_MONTHLY_SALES_FAILURE,
  TOTAL_SALES_BY_CATEGORIES_SUCCESS,
  TOTAL_SALES_BY_CATEGORIES_FAILURE,
  TOTAL_SALES_BY_GROUP_SUCCESS,
  TOTAL_SALES_BY_GROUP_FAILURE,
  GET_LIST_FLOW_SALES_SUCCESS,
  GET_LIST_FLOW_SALES_FAILURE,
  LIST_METAS_VENDAS_BY_COMPANIES_SUCCESS,
  LIST_METAS_VENDAS_BY_COMPANIES_FAILURE,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_SUCESS,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_FAILURE,
  OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_CLEAR,
  EDITAR_PROPOSTA_REQUEST,
  EDITAR_PROPOSTA_SUCESS,
  EDITAR_PROPOSTA_FAILURE,
  EDITAR_PROPOSTA_CLEAR,
  TRANSFERIR_PROPOSTA_REQUEST,
  TRANSFERIR_PROPOSTA_SUCESS,
  TRANSFERIR_PROPOSTA_FAILURE,
  TRANSFERIR_PROPOSTA_CLEAR,
  MULTIPLAS_PROPOSTAS_REQUEST,
  MULTIPLAS_PROPOSTAS_SUCCESS,
  MULTIPLAS_PROPOSTAS_FAILURE,
} from "../actions";

const INIT_STATE = {
  propostas: [],
  propostasCount: {
    aguardando: 0,
    pendente: 0,
    aguardandoCliente: 0,
    grupoCota: 0,
    finalizadas: 0,
    canceladas: 0,
    repescagem: 0,
    arquivadas: 0,
    recusadas: 0,
    empresaId: 0,
    empresas: [],
  },
  loadingPropostas: false,
  hasError: false,
  errorMessage: [],
  filter: {
    Status: 0,
    iDisplayLength: 20,
    iDisplayStart: 1,
    iTotalRecords: 0,
    // iTotalDisplayRecords: 20,
    empresaID: 0,
    Search: "",
    DataInicial: null,
    DataFinal: null,
    bacen: false,
  },
  propostasInformation: {
    countPropostas_Arquivadas: 0,
    countPropostas_Atendimento: 0,
    countPropostas_CadastroPendente: 0,
    countPropostas_Canceladas: 0,
    countPropostas_Completa: 0,
    countPropostas_Estornadas: 0,
    countPropostas_Finalizada: 0,
    countPropostas_Recusadas: 0,
    countPropostas_PagamentoPendente: 0,
    countLeads: 0,
    countLeads_Plano: 0,
    countLeads_Proposta: 0,
    countLeads_Regulamento: 0,
    countLeads_Finalizadas: 0,
    moneyPropostasFinalizadas: 0,
    propostasVendasMes: [],
    categoriasPropostasVendas: [],
    quantityPropostasFinalizadasByGroup: [],
    metasVendasByCompanies: [],
  },
  loading_cards_dashboard: {
    loadingTotalMoneyfinalizadas: false,
    loadingGetListCountPropostas: false,
    loadingGetListMonthlySales: false,
    loadingTotalSalesByCategories: false,
    loadingTotalSalesByGroup: false,
    loadingGetListFlowSales: false,
    loadingMetasVendasByCompanies: false,
  },
  failure_cards: {
    totalMoneyfinalizadasCards: false,
    getListCountPropostasCards: false,
    getListMonthlySalesCards: false,
    totalSalesByCategoriesCards: false,
    totalSalesByGroupCards: false,
    getListFlowSalesCards: false,
    MetasVendasByCompaniesCards: false,
  },
  historico: {
    proposta: {},
    lead: {},
    historicos: [],
    clientesNotificacoes: [],
    pager: {},
  },
  // ClientesNotificacoes: [],
  // Pager: [],
  loadingModalHistorico: false,
  propostaSelectValues: [],
  editarProposta: {
    loading: false,
    error: false,
    errors_messages: [],
    success: false,
  },
  iTotalRecords: 0,
  iTotalDisplayRecords: 20,
  transferirProposta: {
    loading: false,
    error: false,
    errors_messages: [],
    success: false,
  },
  multiplasPropostas: [],
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PROPOSTAS_REQUEST:
      return { ...state, loadingListPropostas: true };
    case PROPOSTAS_COUNT_REQUEST:
      return { ...state, loadingCountPropostas: true };
    case PROPOSTAS_COUNT_SUCCESS:
      return {
        ...state,
        propostasCount: action.payload.propostasCount,
        loadingCountPropostas: false,
      };
    case PROPOSTAS_SUCCESS:
      return {
        ...state,
        propostas: action.payload.data.items,
        iTotalRecords: action.payload.data.iTotalRecords,
        iTotalDisplayRecords: action.payload.data.iTotalRecords,
        loadingListPropostas: false,
      };
    case PROPOSTAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        error_message: action.payload.error_message,
      };
    case PROPOSTAS_PAGINACAO:
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          iDisplayStart: action.payload.iDisplayStart,
        },
      };
    case PROPOSTAS_PAGESIZE:
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          iDisplayLength: action.payload.iDisplayLength,
        },
      };
    case PROPOSTAS_FILTRO_EMPRESA:
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          empresaID: action.payload.empresaID,
        },
      };
    case PROPOSTAS_FILTRO_STATUS:
      const { status, bacen } = action.payload;
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          Status: status,
          bacen,
        },
      };
    case PROPOSTAS_FILTRO_SEARCH:
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          Search: action.payload.Search,
        },
      };
    case PROPOSTAS_FILTRO_DATA:
      return {
        ...state,
        loading: false,
        filter: {
          ...state.filter,
          DataInicial: action.payload.DataInicial,
          DataFinal: action.payload.DataFinal,
        },
      };
    case HISTORICO_PROPOSTA_REQUEST:
      return { ...state, loadingModalHistorico: true };
    case HISTORICO_PROPOSTA_SUCESS:
      return {
        ...state,
        historico: {
          proposta: action.payload.historico.Proposta,
          lead: action.payload.historico.Lead,
          historicos: action.payload.historico.Historico,
          clientesNotificacoes: action.payload.historico.ClientesNotificacoes,
          pager: action.payload.historico.Pager,
        },
        loadingModalHistorico: false,
      };
    case PROPOSTAS_DASHBOARD_REQUEST:
      return {
        ...state,
        loadingPropostas: true,
        loading_cards_dashboard: {
          loadingTotalMoneyfinalizadas: true,
          loadingGetListCountPropostas: true,
          loadingGetListMonthlySales: true,
          loadingTotalSalesByCategories: true,
          loadingTotalSalesByGroup: true,
          loadingGetListFlowSales: true,
          loadingMetasVendasByCompanies: true,
        },
      };
    case PROPOSTAS_DASHBOARD_SUCCESS:
      return {
        ...state,
        loadingPropostas: false,
        propostasInformation: action.payload,
        loading_cards_dashboard: {
          loadingTotalMoneyfinalizadas: false,
          loadingGetListCountPropostas: false,
          loadingGetListMonthlySales: false,
          loadingTotalSalesByCategories: false,
          loadingTotalSalesByGroup: false,
          loadingGetListFlowSales: false,
          loadingMetasVendasByCompanies: false,
        },
      };
    case PROPOSTAS_COUNT_REQUEST:
      return { ...state, loadingPropostas: true };
    case PROPOSTAS_TOTAL_MONEY_FINALIZADAS:
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          moneyPropostasFinalizadas: action.payload.moneyPropostasFinalizadas,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingTotalMoneyfinalizadas: false,
        },
        failure_cards: {
          ...state.failure_cards,
          totalMoneyfinalizadasCards: false,
        },
      };
    case PROPOSTAS_TOTAL_MONEY_FINALIZADAS_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingTotalMoneyfinalizadas: false,
        },
        failure_cards: {
          ...state.failure_cards,
          totalMoneyfinalizadasCards: true,
        },
      };
    case GET_LIST_COUNT_PROPOSTAS_SUCCESS:
      const { list } = action.payload;
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          countPropostas_Arquivadas: list.countPropostas_Arquivadas,
          countPropostas_Atendimento: list.countPropostas_Atendimento,
          countPropostas_CadastroPendente: list.countPropostas_CadastroPendente,
          countPropostas_Canceladas: list.countPropostas_Canceladas,
          countPropostas_Completa: list.countPropostas_Completa,
          countPropostas_Estornadas: list.countPropostas_Estornadas,
          countPropostas_Finalizada: list.countPropostas_Finalizada,
          countPropostas_PagamentoPendente:
            list.countPropostas_PagamentoPendente,
          countPropostas_Recusadas: list.countPropostas_Recusadas,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingGetListCountPropostas: false,
        },
        failure_cards: {
          ...state.failure_cards,
          getListCountPropostasCards: false,
        },
      };
    case GET_LIST_COUNT_PROPOSTAS_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingGetListCountPropostas: false,
        },
        failure_cards: {
          ...state.failure_cards,
          getListCountPropostasCards: true,
        },
      };
    case LIST_MONTHLY_SALES_SUCCESS:
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          propostasVendasMes: action.payload.propostasVendasMes,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingGetListMonthlySales: false,
        },
        failure_cards: {
          ...state.failure_cards,
          getListMonthlySalesCards: false,
        },
      };
    case LIST_MONTHLY_SALES_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingGetListMonthlySales: false,
        },
        failure_cards: {
          ...state.failure_cards,
          getListMonthlySalesCards: true,
        },
      };
    case TOTAL_SALES_BY_CATEGORIES_SUCCESS:
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          categoriasPropostasVendas: action.payload.categoriasPropostasVendas,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingTotalSalesByCategories: false,
        },
        failure_cards: {
          ...state.failure_cards,
          totalSalesByCategoriesCards: false,
        },
      };
    case TOTAL_SALES_BY_CATEGORIES_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingTotalSalesByCategories: false,
        },
        failure_cards: {
          ...state.failure_cards,
          totalSalesByCategoriesCards: true,
        },
      };
    case TOTAL_SALES_BY_GROUP_SUCCESS:
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          quantityPropostasFinalizadasByGroup:
            action.payload.quantityPropostasFinalizadasByGroup,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingTotalSalesByGroup: false,
        },
        failure_cards: {
          ...state.failure_cards,
          totalSalesByGroupCards: false,
        },
      };
    case TOTAL_SALES_BY_GROUP_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingTotalSalesByGroup: false,
        },
        failure_cards: {
          ...state.failure_cards,
          totalSalesByGroupCards: true,
        },
      };
    case GET_LIST_FLOW_SALES_SUCCESS:
      const { listLeads } = action.payload;
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          countLeads: listLeads.countLeads,
          countLeads_Plano: listLeads.countLeads_Plano,
          countLeads_Proposta: listLeads.countLeads_Proposta,
          countLeads_Regulamento: listLeads.countLeads_Regulamento,
          countLeads_Finalizadas: listLeads.countLeads_Finalizadas,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingGetListFlowSales: false,
        },
        failure_cards: {
          ...state.failure_cards,
          getListFlowSalesCards: false,
        },
      };
    case GET_LIST_FLOW_SALES_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingGetListFlowSales: false,
        },
        failure_cards: {
          ...state.failure_cards,
          getListFlowSalesCards: true,
        },
      };
    case LIST_METAS_VENDAS_BY_COMPANIES_SUCCESS:
      return {
        ...state,
        propostasInformation: {
          ...state.propostasInformation,
          metasVendasByCompanies: action.payload.metasVendasByCompanies,
        },
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingMetasVendasByCompanies: false,
        },
        failure_cards: {
          ...state.failure_cards,
          MetasVendasByCompaniesCards: false,
        },
      };
    case LIST_METAS_VENDAS_BY_COMPANIES_FAILURE:
      return {
        ...state,
        loading_cards_dashboard: {
          ...state.loading_cards_dashboard,
          loadingMetasVendasByCompanies: false,
        },
        failure_cards: {
          ...state.failure_cards,
          MetasVendasByCompaniesCards: true,
        },
      };
    case PROPOSTAS_COUNT_SUCCESS:
      return {
        ...state,
        loadingPropostas: false,
        propostasCount: action.payload,
      };
    case PROPOSTAS_COUNT_FAILURE:
      return {
        ...state,
        loadingPropostas: false,
        hasError: true,
        errorMessage: action.payload.errorMessage,
      };
    case OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_REQUEST:
      return {
        ...state,
        propostaSelectValues: {
          ...state.propostaSelectValues,
          loading: true,
        },
      };
    case OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_SUCESS:
      return {
        ...state,
        propostaSelectValues: {
          ...state.propostaSelectValues,
          loading: false,
          Tabelas: action.payload.Tabelas,
          Bens: action.payload.Bens,
          Planos: action.payload.Planos,
        },
      };
    case OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_FAILURE:
      return {
        ...state,
        propostaSelectValues: { loading: false },
      };
    case EDITAR_PROPOSTA_REQUEST:
      return {
        ...state,
        editarProposta: {
          ...state.editarProposta,
          loading: true,
        },
      };
    case EDITAR_PROPOSTA_SUCESS:
      return {
        ...state,
        editarProposta: {
          ...state.editarProposta,
          loading: false,
          error: false,
          errors_messages: [],
          success: true,
        },
      };
    case EDITAR_PROPOSTA_FAILURE:
      return {
        ...state,
        editarProposta: {
          ...state.editarProposta,
          loading: false,
          error: true,
          errors_messages: action.payload.errors_messages,
          success: false,
        },
      };
    case EDITAR_PROPOSTA_CLEAR: {
      return {
        ...state,
        editarProposta: {
          loading: false,
          error: false,
          errors_messages: [],
          success: false,
        },
      };
    }
    /** Transferir proposta */
    case TRANSFERIR_PROPOSTA_REQUEST:
      return {
        ...state,
        transferirProposta: {
          ...state.transferirProposta,
          loading: true,
        },
      };
    case TRANSFERIR_PROPOSTA_SUCESS:
      return {
        ...state,
        transferirProposta: {
          ...state.transferirProposta,
          loading: false,
          error: false,
          errors_messages: [],
          success: true,
        },
      };
    case TRANSFERIR_PROPOSTA_FAILURE:
      return {
        ...state,
        transferirProposta: {
          ...state.transferirProposta,
          loading: false,
          error: true,
          errors_messages: action.payload.errors_messages,
          success: false,
        },
      };
    case TRANSFERIR_PROPOSTA_CLEAR: {
      return {
        ...state,
        transferirProposta: {
          loading: false,
          error: false,
          errors_messages: [],
          success: false,
        },
      };
    }
    /** fim Transferir proposta */
    case OBTER_VALUES_OF_SELECT_PROPOSTA_FINALIZADA_CLEAR:
      return { ...state, propostaSelectValues: { loading: false } };
    case MULTIPLAS_PROPOSTAS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case MULTIPLAS_PROPOSTAS_SUCCESS:
      return {
        ...state,
        loading: false,
        multiplasPropostas: action.payload.payload.data,
      };
    case MULTIPLAS_PROPOSTAS_FAILURE:
      return { ...state };
    default:
      return { ...state };
  }
};

export default reducer;
