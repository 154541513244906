import axios from "axios";

function apiInfo(host) {
  switch (host) {
    case "test":
      return "https://localhost:44329/api";
    case "dev":
      return "https://cd-dev.mycon.com.br/api";
    case "production":
      return "https://cd.mycon.com.br/api";
    case "myhost":
      return "http://localhost/consorcio.digital/api";
    default: 
      return "https://localhost:44329/api";
  }
}

const apiConnect = apiInfo("production");

/** este arquivo não precisa commitar */
export const baseUrl = axios.create({
  baseURL: `${apiConnect}/vendas`,
});

/** esta constante vai para o cep */
export const baseCepUrl = axios.create({
  baseURL: apiConnect,
});
