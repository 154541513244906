import {
  TRANSFERIR_PROPOSTA_CHECK_URL_FAILURE,
  TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST,
  TRANSFERIR_PROPOSTA_CHECK_URL_SUCCESS,
  TRANSFERIR_PROPOSTA_CLEAR_VALUES,
  TRANSFERIR_PROPOSTA_VENDEDOR_CLEAR_INFORMATION,
  TRANSFERIR_PROPOSTA_VENDEDOR_FAILURE,
  TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST,
  TRANSFERIR_PROPOSTA_VENDEDOR_SUCCESS,
} from "../actions";

export const transferirPropostaVendedorRequest = ({ propostaId, url }) => ({
  type: TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST,
  payload: { propostaId, url },
});

export const transferirPropostaVendedorSuccess = ({ message }) => ({
  type: TRANSFERIR_PROPOSTA_VENDEDOR_SUCCESS,
  payload: { message },
});

export const transferirPropostaVendedorFailure = ({ message }) => ({
  type: TRANSFERIR_PROPOSTA_VENDEDOR_FAILURE,
  payload: { message },
});

export const transferirPropostaVendedorCheckUrlRequest = ({
  transfer_url,
}) => ({
  type: TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST,
  payload: { transfer_url },
});

export const transferirPropostaVendedorCheckUrlSuccess = ({
  url_responsavel,
}) => ({
  type: TRANSFERIR_PROPOSTA_CHECK_URL_SUCCESS,
  payload: { url_responsavel },
});

export const transferirPropostaVendedorCheckUrlFailure = ({
  http_response_status,
}) => ({
  type: TRANSFERIR_PROPOSTA_CHECK_URL_FAILURE,
  payload: { http_response_status },
});

export const transferirPropostaVendedorClearValues = () => ({
  type: TRANSFERIR_PROPOSTA_CLEAR_VALUES,
});
export const transferirPropostaVendedorClearReturnInformation = () => ({
  type: TRANSFERIR_PROPOSTA_VENDEDOR_CLEAR_INFORMATION,
});
