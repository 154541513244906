import {
  EMPRESAS_PERMISSOES_FAILURE,
  EMPRESAS_PERMISSOES_REQUEST,
  EMPRESAS_PERMISSOES_SUCCESS,
  EMPRESA_LOGO,
  EMPRESAS_PERMISSOES_COUNT,
} from "../actions";

const INIT_STATE = {
  empresasPermissoes: [],
  empresa_logo: "",
  empresas_count: 0,
  loading: false,
  error: false,
  error_message: "",
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EMPRESAS_PERMISSOES_REQUEST:
      return { ...state, loading: true };
    case EMPRESAS_PERMISSOES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        error_message: "",
        empresasPermissoes: action.payload.empresasPermissoes,
      };
    case EMPRESAS_PERMISSOES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        error_message: action.payload.error_message,
      };
    case EMPRESA_LOGO:
      return { ...state, empresa_logo: action.payload.empresa_logo };
    case EMPRESAS_PERMISSOES_COUNT:
      return { ...state, empresas_count: action.payload.empresas_count };
    default:
      return { ...state };
  }
};

export default reducer;
