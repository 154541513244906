import {
  PROPOSTA_REATIVAR_COTA_CLEAR_,
  PROPOSTA_REATIVAR_COTA_FAILURE,
  PROPOSTA_REATIVAR_COTA_REQUEST,
  PROPOSTA_REATIVAR_COTA_SUCCESS,
} from "../actions";
/**
 * Request inicial para reativar proposta.
 * Precisa ser passado o id para que seja interpolado na string da requisição como parametro do post.
 * Esse metodo está vinculado com o middleware para request na api - Metodo Pos - watchPropostaReativarCota.
 * Adicionando nas constantes a string de verificação
 * @param propostaId = recebe o Id da proposta que vai ser reativada
 * @returns  bate no middleware e no reducer para atualizar o status
 */
const propostaReativarCotaInitialRequest = ({ propostaId }) => ({
  type: PROPOSTA_REATIVAR_COTA_REQUEST,
  payload: { propostaId },
});

/**
 *
 * @param condition = recebe um boolean
 * @param http_response = recebe um número ou uma string
 * @param http_messages = recebe um array de string
 * @returns bate no reducer que atualiza o status da resposta da api para sucesso
 */
const propostaReativarCotaInitialSuccess = ({
  condition,
  http_response,
  http_messages,
}) => ({
  type: PROPOSTA_REATIVAR_COTA_SUCCESS,
  payload: { condition, http_response, http_messages },
});

/**
 * Responsavel por atualizar os erros de acordo com os erros transmitidos pela api.
 * Os erros são constumizados dentro do metodo que recebe o erro, por exemplo,
 * você pode colocar mensagens de sucesso dentro do http_messages, mas esse metodo coloca o 'error' como false,
 * por mais que você coloque http_response = 200,
 * para mensagens de sucesso use o metodo = propostaReativarCotaInitialSuccess({ ..., ..., ...})
 * @param http_response = status code da resposta do erro
 * @param http_messages = mensagens que precisa ser mostrada para o usuário
 * @returns bate no reducer para atualiza o estado de erro -> sempre vai mudar o 'error' como true
 */
const propostaReativarCotaInitialFailure = ({
  http_response,
  http_messages,
}) => ({
  type: PROPOSTA_REATIVAR_COTA_FAILURE,
  payload: { http_response, http_messages },
});

/**
 * @returns Volta para o estado inicial o estado do reducer.
 */
const propostaReativarCotaClearContext = () => ({
  type: PROPOSTA_REATIVAR_COTA_CLEAR_,
});

export {
  propostaReativarCotaInitialRequest,
  propostaReativarCotaInitialSuccess,
  propostaReativarCotaInitialFailure,
  propostaReativarCotaClearContext,
};
