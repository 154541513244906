import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { LOGIN_USER, LOGOUT_USER } from "../actions";
import { baseUrl } from "../../../services";
import { AccountLogin } from "../../../constants/endPointValues";
import { loginUserError, loginUserSuccess } from "./actions";
import { setCurrentUser, setToken } from "../../../helpers/Utils";
import { adminRoot } from "../../../constants/defaultValues";
import { handlerStatusHttpError } from "../../../utils/helpers";

export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

function* loginWithEmailPassword({ payload }) {
  const { history, user } = payload;
  try {
    const { data } = yield call(baseUrl.post, AccountLogin, user);
    if (data.canAccess == true) {
      const { user } = data;

      const currentUser = {
        id: user.id,
        name: user.name,
        email: user.email,
        img: user.imgUrl,
        date: new Date(),
        permissions: user.permissions,
        permissoesEmpresas: user.permissoesEmpresas,
      };

      setCurrentUser(currentUser);
      setToken(data.token);

      yield put(loginUserSuccess(currentUser));

      history.push(adminRoot);
    } else {
      yield put(loginUserError(data.status));
    }
  } catch (ex) {
    const errorHandler = handlerStatusHttpError(ex);
    yield put(loginUserError(errorHandler));
  }
}

export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  history.push("/login");
};

function* logout({ payload }) {
  const { history } = payload;
  setCurrentUser();
  setToken();
  yield call(logoutAsync, history);
}

export default function* rootSaga() {
  yield all([fork(watchLoginUser), fork(watchLogoutUser)]);
}
