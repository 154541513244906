import { fork, all, takeEvery, put, call } from "@redux-saga/core/effects";
import {
  TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST,
  TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST,
} from "../actions";
import {
  transferirPropostaVendedorCheckUrlFailure,
  transferirPropostaVendedorCheckUrlSuccess,
  transferirPropostaVendedorFailure,
  transferirPropostaVendedorSuccess,
} from "./actions";

import { baseUrl } from "../../../services";
import {
  TransferirVendedor,
  TransferirVendedorCheckUrlVendedor,
} from "../../../constants/endPointValues";

export function* watchTransferirPropostaVendedor() {
  yield takeEvery(
    TRANSFERIR_PROPOSTA_VENDEDOR_REQUEST,
    transferirPropostaVendedor
  );
}

export function* watchTransferirPropostaCheckUrlVendedor() {
  yield takeEvery(TRANSFERIR_PROPOSTA_CHECK_URL_REQUEST, checkUrlVendedor);
}

function* transferirPropostaVendedor({ payload }) {
  try {
    const { data } = yield call(baseUrl.post, TransferirVendedor, payload);

    yield put(transferirPropostaVendedorSuccess({ message: data }));
  } catch (error) {
    // --
    const { status } = error.request;

    if (status === 400) {
      const { Message } = JSON.parse(error.request.response);
      yield put(transferirPropostaVendedorFailure({ message: Message }));
      yield put(
        transferirPropostaVendedorCheckUrlFailure({
          http_response_status: status,
        })
      );
    }
  }
}

function* checkUrlVendedor({ payload }) {
  try {
    const { transfer_url } = payload;

    if (transfer_url === "") {
      yield put(
        transferirPropostaVendedorCheckUrlFailure({ http_response_status: 400 })
      );
    } else {
      const { data } = yield call(
        baseUrl.post,
        `${TransferirVendedorCheckUrlVendedor}?parceiroUrl=${transfer_url}`
      );

      yield put(
        transferirPropostaVendedorCheckUrlSuccess({ url_responsavel: data })
      );
    }
  } catch (e) {
    yield put(
      transferirPropostaVendedorCheckUrlFailure({ http_response_status: 400 })
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchTransferirPropostaVendedor),
    fork(watchTransferirPropostaCheckUrlVendedor),
  ]);
}
