import {
  REPORT_OBTER_FILTRO_REQUEST,
  REPORT_OBTER_FILTRO_SUCCESS,
  REPORT_OBTER_FILTRO_FAILURE,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_SUCCESS,
  REPORT_OBTER_RELATORIO_POR_EMPRESAS_FAILURE,
  GERAR_RELATORIO_SUCCESS,
  GERAR_RELATORIO_FAILURE,
  GERAR_RELATORIO_REQUEST,
  CLEAR_FILE_AFTER_DOWNLOAD,
} from "../actions";

const INIT_STATE = {
  loadingReportUI: false,
  error: false,
  errorMessages: [],
  Relatorios: [],
  AllRelatorios: [],
  Empresas: [],
  Status: [],
  FormaPagamento: [],
  loadingEmpresasSelect: false,
  link: null,
  loadingGerarRelatorio: false,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORT_OBTER_FILTRO_REQUEST:
      return { ...state, loadingReportUI: true };
    case REPORT_OBTER_FILTRO_SUCCESS:
      return {
        ...state,
        Relatorios: action.payload.Relatorios,
        AllRelatorios: action.payload.Relatorios,
        Empresas: action.payload.Empresas,
        Status: action.payload.Status,
        FormaPagamento: action.payload.FormaPagamento,
        loadingReportUI: false,
      };
    case REPORT_OBTER_FILTRO_FAILURE:
      return {
        ...state,
        loadingReportUI: false,
        error: true,
        errorMessages: ["Ocorreu um erro ao tentar carregar os filtros"],
      };
    case REPORT_OBTER_RELATORIO_POR_EMPRESAS_REQUEST:
      return { ...state, loadingEmpresasSelect: true };
    case REPORT_OBTER_RELATORIO_POR_EMPRESAS_SUCCESS:
      return {
        ...state,
        loadingEmpresasSelect: false,
        Relatorios: action.payload,
      };
    case REPORT_OBTER_RELATORIO_POR_EMPRESAS_FAILURE:
      return {
        ...state,
        loadingEmpresasSelect: false,
        error: true,
        errorMessages: ["Ocorreu um erro ao tentar carregar os filtros"],
      };
    case GERAR_RELATORIO_REQUEST:
      return { ...state, loadingGerarRelatorio: true };
    case GERAR_RELATORIO_SUCCESS:
      return {
        ...state,
        link: action.payload.link,
        loadingGerarRelatorio: false,
      };
    case GERAR_RELATORIO_FAILURE:
      return {
        ...state,
        error: true,
        loadingGerarRelatorio: false,
        errorMessages: ["Ocorreu um erro ao carregar o relatório"],
      };
    case CLEAR_FILE_AFTER_DOWNLOAD:
      return { ...state, link: null };
    default:
      return { ...state };
  }
};

export default reducer;
