export const AccountLogin = "account/login";
export const UserUpdate = "account/update";
export const CountPropostas = "propostas/count-propostas";
export const ListPropostas = "propostas/list-propostas";
export const GetPermissoesEmpresas = "empresas/get-permissoes-empresas";
export const GetPropostasInformation = "propostas/get-propostas-information";

export const TotalMoneyPropostasFinalizadas =
  "propostas/total-money-propostas-finalizadas";
export const GetListCountPropostas = "propostas/get-list-count-propostas";
export const ListMonthlySales = "propostas/list-monthly-sales";
export const TotalSalesByCategories = "propostas/total-sales-by-categories";
export const TotalSalesByGroup = "propostas/total-sales-by-group";
export const ListFlowSales = "propostas/list-flow-sales";
export const ListMetasSales = "metas-vendas/list-metas-sales";

export const PropostasHistorico = "propostas/proposta-historico";
export const PropostaObterDadosSelectProposta =
  "propostas/obter-dados-select-proposta";
export const GetMultiplasPropostas = "propostas/get-multiplas-propostas";

export const EditarProposta = "/propostas/edit";
export const TransferirProposta = "/propostas/transferir-proposta";
export const ChecagemBacen = "/propostas/checagem-bacen";

/** Metodo de Reativar cota */
export const ReativarCota = "/propostas/reativar-cota";

export const ConsultCep = "/localizacao/endereco-cep";

/** Report endpoint */
export const RelatorioEditarLink = "relatorios/editar";
export const ObterFiltros = "relatorios/obter-filtros";
export const ObterRelatoriosPorEmpresas =
  "relatorios/obter-relatorios-por-empresas";
export const GerarRelatorio = "relatorios/gerar-relatorio";

/** Contemplacao */
export const Contemplacao = "propostas-contemplacoes/list";
export const CreateContemplacao = "propostas-contemplacoes/create";
export const EditContemplacao = "propostas-contemplacoes/edit";
export const LoadPropostasOptions = "propostas-contemplacoes/get-propostas";
export const LoadAssemblyOptions = "propostas-contemplacoes/get-assembleias";
export const GetData = "propostas-contemplacoes/get-data";
export const DeleteContemplacaoProposta = "propostas-contemplacoes/delete";

/** Transferir Vendedores */
export const TransferirVendedorCheckUrlVendedor =
  "propostas/reativar-check-URL";
export const TransferirVendedor = "propostas/transferir-vendedor";
