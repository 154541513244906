import { all, fork, takeEvery, put, call } from "@redux-saga/core/effects";
import { CONSULT_CEP_REQUEST } from "../actions";
import { consultCepFailure, consultCepSuccess, updateFields } from "./actions";

import { baseCepUrl } from "../../../services";
import { ConsultCep } from "../../../constants/endPointValues";

function* watchConsultCep() {
  yield takeEvery(CONSULT_CEP_REQUEST, getCep);
}

function* getCep({ payload }) {
  const { cep } = payload;

  yield put(updateFields({ update_: true }));

  try {
    const { data } = yield call(baseCepUrl.get, `${ConsultCep}?cep=${cep}`);

    if (cep.length < 8) {
      yield put(consultCepFailure());
    }

    yield put(consultCepSuccess({ response: data, update_: true }));
    yield put(updateFields({ update_: false }));
  } catch (ex) {
    yield put(consultCepFailure());
  }
}

export default function* rootSaga() {
  yield all([fork(watchConsultCep)]);
}
